export default {
    "en": {
        "": "",
        "(and {count} more error)": "(and {count} more error)",
        "(and {count} more errors)": "(and {count} more error)|(and {count} more errors)|(and {count} more errors)",
        "* Required fields": "* Required fields",
        "*Required fields": "*Required fields",
        "+": "+",
        "+1": "+1",
        "-": "-",
        "404 - Page not found": "404 - Page not found",
        "{Attribute} {lang}": "{Attribute} {lang}",
        "{Attribute} is invalid": "{Attribute} is invalid",
        "{count} characters": "{count} characters",
        "{model} {action}": "{model} {action}",
        "{variant} image": "{variant} image",
        "About us": "About us",
        "Accept": "Accept",
        "Accepted": "Accepted",
        "Account": "Account",
        "Action": "Action",
        "Actions": "Actions",
        "Activated": "activated",
        "activated": "activated",
        "Active": "Active",
        "Add": "Add",
        "Add {name}": "Add {name}",
        "Add comment": "Add comment",
        "Added product to cart": "Added product to cart",
        "Add menu item": "Add menu item",
        "Add message": "Add message",
        "add one": "add one",
        "Add page block": "Add page block",
        "Add to cart": "Add to cart",
        "Add to favorites": "Add to favorites",
        "Add to shopping cart": "Add to shopping cart",
        "Admin": "Admin",
        "Administrator": "Administrator",
        "Agree": "Agree",
        "All": "All",
        "All {models}": "All {models}",
        "All blogs": "All blogs",
        "Allow cookies": "Allow cookies",
        "All Products": "All Products",
        "All products": "All products",
        "All questions": "All questions",
        "All rights reserved.": "All rights reserved.",
        "Already Reported": "Already Reported",
        "Alt text": "Alt text",
        "Amount": "Amount",
        "Amount of products": "Amount of products",
        "Answer": "Answer",
        "A piece": "A piece",
        "Apply discount code": "Apply discount code",
        "Apply now": "Apply now",
        "Archive": "Archive",
        "Assign": "Assign",
        "Associate": "Associate",
        "A Timeout Occurred": "A Timeout Occurred",
        "Attach": "Attach",
        "Author": "Author",
        "Back": "Back",
        "Back and mark as done": "Back and mark as done",
        "Back and release": "Back and release",
        "Back to home": "Back to home",
        "Back to the shopping cart": "Back to the shopping cart",
        "Backup codes": "Backup codes",
        "Bad Gateway": "Bad Gateway",
        "Bad Request": "Bad Request",
        "Bandwidth Limit Exceeded": "Bandwidth Limit Exceeded",
        "Banner": "Banner",
        "Billing address": "Billing address",
        "Birthday *": "Birthday *",
        "Blog": "Blog",
        "Blogs": "Blogs",
        "Blog Settings": "Blog Settings",
        "Blue Button": "Blue Button",
        "Browse": "Browse",
        "BTW number": "BTW number",
        "Bullet point": "Bullet point",
        "Bullet points": "Bullet points",
        "Button link": "Button link",
        "Buttons": "Buttons",
        "Button text": "Button text",
        "By subscribing you agree to receive our newsletter.": "By subscribing you agree to receive our newsletter.",
        "Cancel": "Cancel",
        "Categories": "Categories",
        "Category": "Category",
        "Category Settings": "Category Settings",
        "Category slider": "Category slider",
        "Change": "Change",
        "Change password": "Change password",
        "Checkout": "Checkout",
        "Checkout page": "Checkout page",
        "Choose": "Choose",
        "Choose {name}": "Choose {name}",
        "Choose file": "Choose file",
        "Choose File": "Choose File",
        "Choose Image": "Choose Image",
        "Choose link type": "Choose link type",
        "City": "City",
        "Click to copy": "Click to copy",
        "Client": "Client",
        "Client Closed Request": "Client Closed Request",
        "Clients": "Clients",
        "Close": "Close",
        "Close conversation": "Close conversation",
        "closed": "closed",
        "CoC": "CoC",
        "Coc:": "Coc:",
        "Coc number": "Coc number",
        "Code": "Code",
        "code": "code",
        "Collapse": "Collapse",
        "Collapse All": "Collapse All",
        "Comment": "Comment",
        "Company details": "Company details",
        "Company name": "Company name",
        "Complete": "Complete",
        "Concept in only visible to logged in users, draft is not visible to anyone": "Concept in only visible to logged in users, draft is not visible to anyone",
        "Conditional Page link": "Conditional Page link",
        "Confirm": "Confirm",
        "Confirm Password": "Confirm Password",
        "Confirm password": "Confirm password",
        "Conflict": "Conflict",
        "Connect": "Connect",
        "Connection Closed Without Response": "Connection Closed Without Response",
        "Connection Timed Out": "Connection Timed Out",
        "Consultant": "Consultant",
        "Contact": "Contact",
        "Contact information": "Contact information",
        "Content": "Content",
        "Content (Collapsible)": "Content (Collapsible)",
        "Continue": "Continue",
        "Conversation": "Conversation",
        "Conversations": "Conversations",
        "Coupon": "Coupon",
        "Coupon applied": "Coupon applied",
        "Coupon can no longer be used": "Coupon can no longer be used",
        "Coupon not found": "Coupon not found",
        "Coupons": "Coupons",
        "Create": "Create",
        "Create {model}": "Create {model}",
        "Create {name}": "Create {name}",
        "Create an account": "Create an account",
        "created": "created",
        "Created": "Created",
        "Created at": "Created at",
        "Create follow-up": "Create follow-up",
        "Create order": "Create order",
        "Currently no stock": "Currently no stock",
        "Current Password": "Current Password",
        "Customers will be shown the cheapest applicable shipping rate from each group. Currently existing groups: {groups}": "Customers will be shown the cheapest applicable shipping rate from each group. Currently existing groups: {groups}",
        "Dangerously high": "Dangerously high",
        "Dangerously high explanation": "Dangerously high explanation",
        "Dangerously Low": "Dangerously Low",
        "Dangerously low explanation": "Dangerously low explanation",
        "Dashboard": "Dashboard",
        "Date": "Date",
        "Date of birth": "Date of birth",
        "Deactivate": "Deactivate",
        "Deactivated": "deactivated",
        "deactivated": "deactivated",
        "Decline cookies": "Decline cookies",
        "Default consultant": "Default consultant",
        "De laatste trends": "De laatste trends",
        "Delete": "Delete",
        "Delete {name}": "Delete {name}",
        "deleted": "deleted",
        "Delete filters": "Delete filters",
        "Delivery options": "Delivery options",
        "Delivery or pick up?": "Delivery or pick up?",
        "Description": "Description",
        "description": "description",
        "Desktop": "Desktop",
        "Detach": "Detach",
        "Details": "Details",
        "Disable": "Disable",
        "Disable swiper": "Disable swiper",
        "Disabling a coupon will prevent it from being used": "Disabling a coupon will prevent it from being used",
        "Discard": "Discard",
        "Disclaimer": "Disclaimer",
        "Discount": "Discount",
        "Discount active": "Discount active",
        "Discount code": "Discount code",
        "done": "done",
        "Done": "Done",
        "Down": "Down",
        "Download app": "Download app",
        "Download backup codes and continue.": "Download backup codes and continue.",
        "Download invoice": "Download invoice",
        "Due at": "Due at",
        "Duplicate": "Duplicate",
        "Duplicate {name}": "Duplicate {name}",
        "E-mail": "E-mail",
        "E-mail *": "E-mail *",
        "Edit": "Edit",
        "Edit {model}": "Edit {model}",
        "Edit {name}": "Edit {name}",
        "Edit account": "Edit account",
        "Email": "Email",
        "email": "email",
        "Email address": "Email address",
        "Enable": "Enable",
        "End": "End",
        "errors": "errors",
        "Excerpt": "Excerpt",
        "excerpt": "excerpt",
        "Expand": "Expand",
        "Expand All": "Expand All",
        "Expectation Failed": "Expectation Failed",
        "Explanation": "Explanation",
        "Export": "Export",
        "External Link": "External Link",
        "External Test": "External Test",
        "External Tester": "External Tester",
        "Facebook": "Facebook",
        "Facebook link": "Facebook link",
        "Failed Dependency": "Failed Dependency",
        "Faq": "Faq",
        "Faqs": "Faqs",
        "Fast delivery": "Fast delivery",
        "Favorites": "Favorites",
        "favorites": "favorites",
        "Featured Image": "Featured Image",
        "Female": "Female",
        "File": "File",
        "Files": "Files",
        "Fill in the 6-digit code": "Fill in the 6-digit code",
        "Filter": "Filter",
        "Filters": "Filters",
        "First name": "First name",
        "First name *": "First name *",
        "First things first": "First things first",
        "Fixed": "Fixed",
        "Focal point": "Focal point",
        "Follow up to": "Follow up to",
        "Follow up tot": "Follow up tot",
        "Follow us": "Follow us",
        "Follow us on": "Follow us on",
        "Forbidden": "Forbidden",
        "Forgot password": "Forgot password",
        "Forgot password?": "Forgot password?",
        "Forgot your password? Send a password reset link": "Forgot your password? Send a password reset link",
        "Form submission": "Form submission",
        "Form submissions": "Form submissions",
        "Found": "Found",
        "Free": "Free",
        "FREE SHIPPING WITH ORDERS OVER €75": "FREE SHIPPING WITH ORDERS OVER €75",
        "From the authenticator app": "From the authenticator app",
        "Gain muscle product": "Gain muscle product",
        "Gallery": "Gallery",
        "Gateway Timeout": "Gateway Timeout",
        "General slider": "General slider",
        "Generate variants": "Generate variants",
        "Goal": "Goal",
        "Go Home": "Go Home",
        "Gone": "Gone",
        "Google Authenticator": "Google Authenticator",
        "Google Maps URL": "Google Maps URL",
        "Google maps URL": "Google maps URL",
        "Go to page {page}": "Go to page {page}",
        "GRATIS VERZENDING BIJ BESTEDING VAN € 75,-": "GRATIS VERZENDING BIJ BESTEDING VAN € 75,-",
        "Group": "Group",
        "Has image": "Has image",
        "Heading": "Heading",
        "Hello": "Hello",
        "Hello!": "Hello!",
        "Hide": "Hide",
        "Hide {name}": "Hide {name}",
        "Hide on page": "Hide on page",
        "High": "High",
        "Highest quality": "Highest quality",
        "High explanation": "High explanation",
        "Hits": "Hits",
        "Home": "Home",
        "Hoogste kwaliteit": "Hoogste kwaliteit",
        "HTTP Version Not Supported": "HTTP Version Not Supported",
        "I'm a teapot": "I'm a teapot",
        "I agree with the": "I agree with the",
        "I am new here": "I am new here",
        "Icon": "Icon",
        "If checked, the image will be full width.": "If checked, the image will be full width.",
        "If checked, the image will have a gradient. Only has effect if \"Image full width\" is checked.": "If checked, the image will have a gradient. Only has effect if \"Image full width\" is checked.",
        "If enabled, and no image is uploaded, the banner from the homepage will be shown.": "If enabled, and no image is uploaded, the banner from the homepage will be shown.",
        "If left empty, the page title will be used.": "If left empty, the page title will be used.",
        "If left empty, the title will be used.": "If left empty, the title will be used.",
        "If you're having trouble clicking the \"{actionText}\" button, copy and paste the URL belowninto your web browser:": "If you're having trouble clicking the \"{actionText}\" button, copy and paste the URL belowninto your web browser:",
        "If you did not create an account, no further action is required.": "If you did not create an account, no further action is required.",
        "If you did not request a password reset, no further action is required.": "If you did not request a password reset, no further action is required.",
        "I have saved the backup codes": "I have saved the backup codes",
        "Image": "Image",
        "Image CTA": "Image CTA",
        "Image full width": "Image full width",
        "Images": "Images",
        "Impersonate": "Impersonate",
        "Impersonation": "Impersonation",
        "Import": "Import",
        "Import {name}": "Import {name}",
        "IM Used": "IM Used",
        "Including VAT": "Including VAT",
        "Index Page": "Index Page",
        "Inloggen": "Inloggen",
        "In shoppingcart": "In shoppingcart",
        "Inspection": "Inspection",
        "inspiration guide.": "inspiration guide.",
        "Instagram": "Instagram",
        "Instagram link": "Instagram link",
        "Instruction title": "Instruction title",
        "Insufficient Storage": "Insufficient Storage",
        "Internal comment": "Internal comment",
        "Internal comments": "Internal comments",
        "Internal Server Error": "Internal Server Error",
        "Introduction": "Introduction",
        "Invalid JSON was returned from the route.": "Invalid JSON was returned from the route.",
        "Invalid phone number": "Invalid phone number",
        "Invalid SSL Certificate": "Invalid SSL Certificate",
        "It is recommended to keep the {attribute} between {min} and {max} characters long": "It is recommended to keep the {attribute} between {min} and {max} characters long",
        "KVK number": "KVK number",
        "Label": "Label",
        "Label Button": "Label Button",
        "Language": "Language",
        "Last message on": "Last message on",
        "Last name": "Last name",
        "Last name *": "Last name *",
        "Last refresh was {quantity} {unit} ago": "Last refresh was {quantity} {unit} ago",
        "Leave empty for no end date": "Leave empty for no end date",
        "Leave empty to generate a random code. Not editable after creation.": "Leave empty to generate a random code. Not editable after creation.",
        "Leave empty to use 'Related products'": "Leave empty to use 'Related products'",
        "Leave empty to use parent details": "Leave empty to use parent details",
        "Length Required": "Length Required",
        "Like": "Like",
        "Link Button": "Link Button",
        "Linkedin link": "Linkedin link",
        "Load": "Load",
        "Localize": "Localize",
        "Location": "Location",
        "Locked": "Locked",
        "Log In": "Log In",
        "Login": "Login",
        "Login / Register": "Login / Register",
        "Login to your account in our secure environment": "Login to your account in our secure environment",
        "Log Out": "Log Out",
        "Logout": "Logout",
        "Loop Detected": "Loop Detected",
        "Lose weight product": "Lose weight product",
        "Low": "Low",
        "Low explanation": "Low explanation",
        "Made by:": "Made by:",
        "Maintain muscle product": "Maintain muscle product",
        "Maintenance Mode": "Maintenance Mode",
        "Main variant": "Main variant",
        "Make main": "Make main",
        "Make your decision:": "Make your decision:",
        "Male": "Male",
        "Mark as done": "Mark as done",
        "Max users": "Max users",
        "Medical": "Medical",
        "Meer informatie": "Meer informatie",
        "Menu": "Menu",
        "Menu items": "Menu items",
        "Menus": "Menus",
        "Message": "Message",
        "Message *": "Message *",
        "Messages": "Messages",
        "Method Not Allowed": "Method Not Allowed",
        "Metric Name": "Metric Name",
        "Microsoft Authenticator": "Microsoft Authenticator",
        "Minimal order amount": "Minimal order amount",
        "minus icon": "minus icon",
        "Misdirected Request": "Misdirected Request",
        "Mobile": "Mobile",
        "More": "More",
        "More information": "More information",
        "More settings can be set in the Page Block Settings page": "More settings can be set in the Page Block Settings page",
        "Most sold": "Most sold",
        "Moved Permanently": "Moved Permanently",
        "Multi-Status": "Multi-Status",
        "Multiple Choices": "Multiple Choices",
        "Mute client": "Mute client",
        "Muted": "Muted",
        "muted": "muted",
        "Name": "Name",
        "name": "name",
        "Name *": "Name *",
        "Name client": "Name client",
        "Need inspiration in advance? Browse our": "Need inspiration in advance? Browse our",
        "Network Authentication Required": "Network Authentication Required",
        "Network Connect Timeout Error": "Network Connect Timeout Error",
        "Network Read Timeout Error": "Network Read Timeout Error",
        "New": "New",
        "New {name}": "New {name}",
        "Newest": "Newest",
        "New Password": "New Password",
        "New URL": "New URL",
        "Next": "Next",
        "No": "No",
        "No Content": "No Content",
        "No favorites yet": "No favorites yet",
        "No items": "No items",
        "Non-Authoritative Information": "Non-Authoritative Information",
        "None": "None",
        "No options have been created yet": "No options have been created yet",
        "No orders yet": "No orders yet",
        "No products found. Try again": "No products found. Try again",
        "No results found": "No results found",
        "No settings available": "No settings available",
        "Not Acceptable": "Not Acceptable",
        "Not available": "Not available",
        "Not available in this combination": "Not available in this combination",
        "Not editable after creation.": "Not editable after creation.",
        "Not Extended": "Not Extended",
        "Not Found": "Not Found",
        "Not Implemented": "Not Implemented",
        "Not implemented yet": "Not implemented yet",
        "Not Modified": "Not Modified",
        "Number": "Number",
        "of": "of",
        "OK": "OK",
        "Old URL": "Old URL",
        "On your phone or tablet with camera. We can advise you to use {google} or {microsoft}.": "On your phone or tablet with camera. We can advise you to use {google} or {microsoft}.",
        "Open": "Open",
        "Open in a current window": "Open in a current window",
        "Open in a new window": "Open in a new window",
        "Open in a parent frame": "Open in a parent frame",
        "Opening hours": "Opening hours",
        "Openings hours": "Openings hours",
        "Open in new tab": "Open in new tab",
        "Open in the topmost frame": "Open in the topmost frame",
        "Open on the website": "Open on the website",
        "Open the authenticator app, add a new entry and scan the QR code": "Open the authenticator app, add a new entry and scan the QR code",
        "Options": "Options",
        "Orange Button": "Orange Button",
        "Order": "Order",
        "Order now": "Order now",
        "Order number": "Order number",
        "Orders": "Orders",
        "Order was created": "Order was created",
        "Origin Is Unreachable": "Origin Is Unreachable",
        "Overview": "Overview",
        "Page": "Page",
        "Page blocks": "Page blocks",
        "Page block settings": "Page block settings",
        "Page button": "Page button",
        "Page Content": "Page Content",
        "Page Expired": "Page Expired",
        "Page link": "Page link",
        "Pages": "Pages",
        "Page Title": "Page Title",
        "Pagination Navigation": "Pagination Navigation",
        "Paragraph": "Paragraph",
        "Parent category": "Parent category",
        "Partial Content": "Partial Content",
        "Password": "Password",
        "Password reset": "Password reset",
        "Password reset link has been sent": "Password reset link has been sent",
        "Paste or type the code we sent a code to your e-mail.": "Paste or type the code we sent a code to your e-mail.",
        "Paste or type the code we sent a code to your phone number.": "Paste or type the code we sent a code to your phone number.",
        "Payload Too Large": "Payload Too Large",
        "Payment": "Payment",
        "Payment Required": "Payment Required",
        "Payments": "Payments",
        "Percentage": "Percentage",
        "Perfecte pasvorm": "Perfecte pasvorm",
        "Perfect fit": "Perfect fit",
        "Permanent": "Permanent",
        "Permanent Redirect": "Permanent Redirect",
        "Phone": "Phone",
        "Phone *": "Phone *",
        "Phone number": "Phone number",
        "phone number": "phone number",
        "phone_number": "phone_number",
        "Pick-up": "Pick-up",
        "Place": "Place",
        "Place order": "Place order",
        "Please click the button below to verify your email address.": "Please click the button below to verify your email address.",
        "Please use the link in your email to view your order.": "Please use the link in your email to view your order.",
        "plus icon": "plus icon",
        "Postal code": "Postal code",
        "Postcode": "Postcode",
        "Precondition Failed": "Precondition Failed",
        "Precondition Required": "Precondition Required",
        "Premium quality": "Premium quality",
        "Preview": "Preview",
        "Previous": "Previous",
        "Price": "Price",
        "Price (high to low)": "Price (high to low)",
        "Price (low to high)": "Price (low to high)",
        "Price: High - Low": "Price: High - Low",
        "Price: Low - High": "Price: Low - High",
        "Print": "Print",
        "Priority": "Priority",
        "Privacy declaration": "Privacy declaration",
        "Privacy page": "Privacy page",
        "Processing": "Processing",
        "Product": "Product",
        "Product details": "Product details",
        "Product information": "Product information",
        "Product option": "Product option",
        "Product options": "Product options",
        "Products": "Products",
        "products": "Products",
        "Product Settings": "Product Settings",
        "Product slider": "Product slider",
        "Proxy Authentication Required": "Proxy Authentication Required",
        "Publish date": "Publish date",
        "Published At": "Published At",
        "Published from": "Published from",
        "Published until": "Published until",
        "Quantity": "Quantity",
        "Question": "Question",
        "Railgun Error": "Railgun Error",
        "Randomize image": "Randomize image",
        "Range Not Satisfiable": "Range Not Satisfiable",
        "Reactivate": "Reactivate",
        "Read more": "Read more",
        "Recommended products": "Recommended products",
        "Record": "Record",
        "Redirect": "Redirect",
        "Redirects": "Redirects",
        "Regards": "Regards",
        "Register": "Register",
        "Register to your account in our secure environment": "Register to your account in our secure environment",
        "Related products": "Related products",
        "Remove": "Remove",
        "Remove discount code": "Remove discount code",
        "Remove from favorites": "Remove from favorites",
        "remove one": "remove one",
        "remove product": "remove product",
        "Remove product from cart": "Remove product from cart",
        "Reopened subtask": "Reopened subtask",
        "Request Header Fields Too Large": "Request Header Fields Too Large",
        "Request Timeout": "Request Timeout",
        "Resend {attribute}": "Resend {attribute}",
        "Reserved By": "Reserved By",
        "Reset Content": "Reset Content",
        "Reset Password": "Reset Password",
        "Reset password": "Reset password",
        "Reset Password Notification": "Reset Password Notification",
        "Restore": "Restore",
        "Restore {name}": "Restore {name}",
        "Results": "results",
        "results": "results",
        "Results are shared": "Results are shared",
        "Retry With": "Retry With",
        "Reviews": "Reviews",
        "RGB color": "RGB color",
        "Role": "Role",
        "role": "role",
        "Roles": "Roles",
        "Sales": "Sales",
        "Sampled at": "Sampled at",
        "Save": "Save",
        "Save & Close": "Save & Close",
        "Save & Return": "Save & Return",
        "Save {name}": "Save {name}",
        "Save in favorites": "Save in favorites",
        "Save these codes on a secure place if you can’t get access with your Authenticator app.": "Save these codes on a secure place if you can’t get access with your Authenticator app.",
        "Scanning not possible? Fill in this code in the app.": "Scanning not possible? Fill in this code in the app.",
        "Scan the QR code": "Scan the QR code",
        "Search": "Search",
        "Search {name}": "Search {name}",
        "Secure payment": "Secure payment",
        "See all faqs": "See all faqs",
        "See Other": "See Other",
        "Select": "Select",
        "Select a {model}": "Select a {model}",
        "Select All": "Select All",
        "Select a page": "Select a page",
        "Select a template to add the corresponding blocks.": "Select a template to add the corresponding blocks.",
        "Select a template to add the corresponding blocks. Note that this cannot be changed after page has been created": "Select a template to add the corresponding blocks. Note that this cannot be changed after page has been created",
        "Select a test": "Select a test",
        "Select a type": "Select a type",
        "Send": "Send",
        "Send and return": "Send and return",
        "Send a password reset link": "Send a password reset link",
        "Send application": "Send application",
        "Send email": "Send email",
        "Send link": "Send link",
        "Seo Settings": "Seo Settings",
        "SEO title": "SEO title",
        "Server Error": "Server Error",
        "Service": "Service",
        "Service Unavailable": "Service Unavailable",
        "Session Has Expired": "Session Has Expired",
        "Sets": "Sets",
        "Settings": "Settings",
        "Set Two Factor Authentication": "Set Two Factor Authentication",
        "Sex": "Sex",
        "shared": "shared",
        "Share results": "Share results",
        "Shipping address": "Shipping address",
        "Shipping address same as billing address": "Shipping address same as billing address",
        "Shipping and billing address": "Shipping and billing address",
        "Shipping and return information": "Shipping and return information",
        "Shipping cost": "Shipping cost",
        "Shipping method": "Shipping method",
        "Shipping Rate": "Shipping Rate",
        "shipping rate": "shipping rate",
        "Shipping Rates": "Shipping Rates",
        "Shop": "Shop",
        "Shopping cart": "Shopping cart",
        "Shopping cart page": "Shopping cart page",
        "Show": "Show",
        "Show {name}": "Show {name}",
        "Show All": "Show All",
        "Showing": "Showing",
        "Show less": "Show less",
        "Show more": "Show more",
        "Show products": "Show products",
        "Show rectangle": "Show rectangle",
        "sidebar": "sidebar",
        "Sign In": "Sign In",
        "Sign out": "Sign out",
        "Sign up for our newsletter": "Sign up for our newsletter",
        "Size chart image": "Size chart image",
        "Slides": "Slides",
        "Slug": "Slug",
        "Social media": "Social media",
        "Solve": "Solve",
        "Sort": "Sort",
        "SSL Handshake Failed": "SSL Handshake Failed",
        "Standard": "Standard",
        "Start": "Start",
        "Start date:": "Start date:",
        "Started at": "Started at",
        "Status": "Status",
        "Statuses": "Statuses",
        "Stock": "Stock",
        "Stop": "Stop",
        "Street name": "Street name",
        "Street number": "Street number",
        "Street number addition": "Street number addition",
        "Subject": "Subject",
        "Subject *": "Subject *",
        "Sub menu": "Sub menu",
        "Submit": "Submit",
        "Subscribe": "Subscribe",
        "SubTasks": "SubTasks",
        "Subtitle": "Subtitle",
        "subtitle": "subtitle",
        "Subtotal": "Subtotal",
        "Switch": "Switch",
        "Switching Protocols": "Switching Protocols",
        "Switch To Role": "Switch To Role",
        "Tag": "Tag",
        "Tags": "Tags",
        "Task": "Task",
        "Tasks": "Tasks",
        "Tel": "Tel",
        "Temporary Redirect": "Temporary Redirect",
        "Terms and Conditions": "Terms and Conditions",
        "terms and conditions": "terms and conditions",
        "Terms page": "Terms page",
        "Test": "Test",
        "test": "test",
        "Tests": "Tests",
        "Test set": "Test set",
        "test set": "test set",
        "Test sets": "Test sets",
        "Text": "Text",
        "Thanks for your message, we will contact you as soon as possible.": "Thanks for your message, we will contact you as soon as possible.",
        "Thank you for shopping with us!": "Thank you for shopping with us!",
        "Thank you for your application!": "Thank you for your application!",
        "Thank you for your message!": "Thank you for your message!",
        "Thank you for your message.": "Thank you for your message.",
        "Thank you for your sign up!": "Thank you for your sign up!",
        "Thank you page settings": "Thank you page settings",
        "The {attribute} has already been taken.": "The {attribute} has already been taken.",
        "The {attribute} must be verified.": "The {attribute} must be verified.",
        "The {attribute} must contain at least one letter.": "The {attribute} must contain at least one letter.",
        "The {attribute} must contain at least one number.": "The {attribute} must contain at least one number.",
        "The {attribute} must contain at least one symbol.": "The {attribute} must contain at least one symbol.",
        "The {attribute} must contain at least one uppercase and one lowercase letter.": "The {attribute} must contain at least one uppercase and one lowercase letter.",
        "The coupon has already been used.": "The coupon has already been used.",
        "The coupon has expired.": "The coupon has expired.",
        "The given {attribute} has appeared in a data leak. Please choose a different {attribute}.": "The given {attribute} has appeared in a data leak. Please choose a different {attribute}.",
        "The given data was invalid.": "The given data was invalid.",
        "The latest trends": "The latest trends",
        "The password field is required when creating an account.": "The password field is required when creating an account.",
        "The provided {attribute} is invalid.": "The provided {attribute} is invalid.",
        "The provided code was invalid.": "The provided code was invalid.",
        "The provided password does not match your current password.": "The provided password does not match your current password.",
        "The provided two factor authentication code was invalid.": "The provided two factor authentication code was invalid.",
        "The response is not a streamed response.": "The response is not a streamed response.",
        "The response is not a view.": "The response is not a view.",
        "The tests cannot be changed once the test set is created.": "The tests cannot be changed once the test set is created.",
        "This {attribute} has already been used": "This {attribute} has already been used",
        "This action is unauthorized.": "This action is unauthorized.",
        "This area is restricted to medical medic members only.": "This area is restricted to medical medic members only.",
        "This area is restricted to staff members only.": "This area is restricted to staff members only.",
        "This is your first login": "This is your first login",
        "This password reset link will expire in {count} minutes.": "This password reset link will expire in {count} minutes.",
        "This shipping rate is not applicable to your cart.": "This shipping rate is not applicable to your cart.",
        "This task is reserved by {userName}": "This task is reserved by {userName}",
        "This task is reserved by {userName}.": "This task is reserved by {userName}.",
        "Tiktoc link": "Tiktoc link",
        "TikTok": "TikTok",
        "Times used": "Times used",
        "Title": "Title",
        "title": "title",
        "to": "to",
        "TODO": "TODO",
        "Toggle navigation": "Toggle navigation",
        "token": "token",
        "Too Early": "Too Early",
        "Too Many Requests": "Too Many Requests",
        "Totaal products ({number})": "Totaal products ({number})",
        "Total": "Total",
        "Total saving": "Total saving",
        "To use Top Chefs Two Factor Authentication is required as extra security, you can set this now": "To use Top Chefs Two Factor Authentication is required as extra security, you can set this now",
        "Trans": "Trans",
        "Translate": "Translate",
        "Translate It": "Translate It",
        "Two Factor Authentication - Backup codes": "Two Factor Authentication - Backup codes",
        "Two factor challenge": "Two factor challenge",
        "Type": "Type",
        "Type job position": "Type job position",
        "Unauthorized": "Unauthorized",
        "Unavailable For Legal Reasons": "Unavailable For Legal Reasons",
        "Unisex": "Unisex",
        "Unknown Error": "Unknown Error",
        "Unmute client": "Unmute client",
        "unmuted": "unmuted",
        "Unpack": "Unpack",
        "Unprocessable Entity": "Unprocessable Entity",
        "Unsubscribe": "Unsubscribe",
        "Unsupported Media Type": "Unsupported Media Type",
        "Up": "Up",
        "Update": "Update",
        "Update {name}": "Update {name}",
        "Update account": "Update account",
        "updated": "updated",
        "Update your info here.": "Update your info here.",
        "Upgrade Required": "Upgrade Required",
        "Upload C.V *": "Upload C.V *",
        "Upload Motivation *": "Upload Motivation *",
        "URI Too Long": "URI Too Long",
        "Url": "Url",
        "url": "url",
        "Use Proxy": "Use Proxy",
        "User": "User",
        "user": "user",
        "Users": "Users",
        "Use `-1` for unlimited usage": "Use `-1` for unlimited usage",
        "USPs": "USPs",
        "Uw winkelmand is leeg": "Uw winkelmand is leeg",
        "Vacancyfilters": "Vacancyfilters",
        "Value": "Value",
        "Values": "Values",
        "Variant": "Variant",
        "Variant Also Negotiates": "Variant Also Negotiates",
        "Variants": "Variants",
        "VAT": "VAT",
        "VAT:": "VAT:",
        "VAT number": "VAT number",
        "Verify": "Verify",
        "Verify {attribute}": "Verify {attribute}",
        "Verify Email Address": "Verify Email Address",
        "View": "View",
        "View {name}": "View {name}",
        "View order": "View order",
        "View vacancy": "View vacancy",
        "Waiting for payment conformation": "Waiting for payment conformation",
        "Warning: limited stock!": "Warning: limited stock!",
        "Washing instructions": "Washing instructions",
        "Was unable to find an address with this postcode and street number": "Was unable to find an address with this postcode and street number",
        "Web Page": "Web Page",
        "Web Page Settings": "Web Page Settings",
        "Web Server is Down": "Web Server is Down",
        "Website": "Website",
        "We have done our best, but it seems that the page you requested does not exist (anymore) or that it may have moved.": "We have done our best, but it seems that the page you requested does not exist (anymore) or that it may have moved.",
        "We have received your message. One of our employees will contact you as soon as possible.": "We have received your message. One of our employees will contact you as soon as possible.",
        "Welcome": "Welcome",
        "Welcome back": "Welcome back",
        "Welcome to {page}": "Welcome to {page}",
        "What is my size?": "What is my size?",
        "When enabled, an email will be sent to the user, letting them know their account has been created.": "When enabled, an email will be sent to the user, letting them know their account has been created.",
        "Whoops!": "Whoops!",
        "With gradient": "With gradient",
        "Yes": "Yes",
        "You are receiving this email because we received a password reset request for your account.": "You are receiving this email because we received a password reset request for your account.",
        "You are subscribed to the newsletter": "You are subscribed to the newsletter",
        "Your account": "Your account",
        "Your account for {siteName}": "Your account for {siteName}",
        "Your account is inactive. Please contact support.": "Your account is inactive. Please contact support.",
        "Your account is inactive. Please contact support. To use the website as a guest, please refresh the page.": "Your account is inactive. Please contact support. To use the website as a guest, please refresh the page.",
        "Your current locale is {locale}": "Your current locale is {locale}",
        "Your experience on this site is enhanced by the use of cookies.": "Your experience on this site is enhanced by the use of cookies.",
        "Your last login was {relativeTime} on {dateTime}": "Your last login was {relativeTime} on {dateTime}",
        "Your Order": "Your Order",
        "Your order": "Your order",
        "Your order was created.": "Your order was created.",
        "Your Profile": "Your Profile",
        "Your shopping cart is empty": "Your shopping cart is empty",
        "YouTube": "YouTube",
        "Youtube link": "Youtube link",
        "validation": {
            "accepted": "The {attribute} must be accepted.",
            "accepted_if": "The {attribute} must be accepted when {other} is {value}.",
            "active_url": "The {attribute} is not a valid URL.",
            "after": "The {attribute} must be a date after {date}.",
            "after_or_equal": "The {attribute} must be a date after or equal to {date}.",
            "alpha": "The {attribute} must only contain letters.",
            "alpha_dash": "The {attribute} must only contain letters, numbers, dashes and underscores.",
            "alpha_num": "The {attribute} must only contain letters and numbers.",
            "array": "The {attribute} must be an array.",
            "ascii": "The {attribute} field must only contain single-byte alphanumeric characters and symbols.",
            "before": "The {attribute} must be a date before {date}.",
            "before_or_equal": "The {attribute} must be a date before or equal to {date}.",
            "between": {
                "array": "The {attribute} must have between {min} and {max} items.",
                "file": "The {attribute} must be between {min} and {max} kilobytes.",
                "numeric": "The {attribute} must be between {min} and {max}.",
                "string": "The {attribute} must be between {min} and {max} characters."
            },
            "boolean": "The {attribute} field must be true or false.",
            "can": "The {attribute} field contains an unauthorized value.",
            "confirmed": "The {attribute} confirmation does not match.",
            "current_password": "The password is incorrect.",
            "date": "The {attribute} is not a valid date.",
            "date_equals": "The {attribute} must be a date equal to {date}.",
            "date_format": "The {attribute} does not match the format {format}.",
            "decimal": "The {attribute} field must have {decimal} decimal places.",
            "declined": "The {attribute} must be declined.",
            "declined_if": "The {attribute} must be declined when {other} is {value}.",
            "different": "The {attribute} and {other} must be different.",
            "digits": "The {attribute} must be {digits} digits.",
            "digits_between": "The {attribute} must be between {min} and {max} digits.",
            "dimensions": "The {attribute} has invalid image dimensions.",
            "distinct": "The {attribute} field has a duplicate value.",
            "doesnt_end_with": "The {attribute} field must not end with one of the following: {values}.",
            "doesnt_start_with": "The {attribute} field must not start with one of the following: {values}.",
            "email": "The {attribute} must be a valid email address.",
            "ends_with": "The {attribute} must end with one of the following: {values}.",
            "enum": "The {attribute} field value is not in the list of allowed values.",
            "exists": "The {attribute} field value does not exist.",
            "extensions": "The {attribute} field must have one of the following extensions: {values}.",
            "file": "The {attribute} must be a file.",
            "filled": "The {attribute} field must have a value.",
            "gt": {
                "array": "The {attribute} must have more than {value} items.",
                "file": "The {attribute} must be greater than {value} kilobytes.",
                "numeric": "The {attribute} must be greater than {value}.",
                "string": "The {attribute} must be greater than {value} characters."
            },
            "gte": {
                "array": "The {attribute} must have {value} items or more.",
                "file": "The {attribute} must be greater than or equal to {value} kilobytes.",
                "numeric": "The {attribute} must be greater than or equal to {value}.",
                "string": "The {attribute} must be greater than or equal to {value} characters."
            },
            "hex_color": "The {attribute} field must be a valid hexadecimal color.",
            "image": "The {attribute} must be an image.",
            "in": "The {attribute} field value is not in the list of allowed values.",
            "in_array": "The {attribute} field does not exist in {other}.",
            "integer": "The {attribute} must be an integer.",
            "ip": "The {attribute} must be a valid IP address.",
            "ipv4": "The {attribute} must be a valid IPv4 address.",
            "ipv6": "The {attribute} must be a valid IPv6 address.",
            "json": "The {attribute} must be a valid JSON string.",
            "list": "The {attribute} field must be a list.",
            "lowercase": "The {attribute} field must be lowercase.",
            "lt": {
                "array": "The {attribute} must have less than {value} items.",
                "file": "The {attribute} must be less than {value} kilobytes.",
                "numeric": "The {attribute} must be less than {value}.",
                "string": "The {attribute} must be less than {value} characters."
            },
            "lte": {
                "array": "The {attribute} must not have more than {value} items.",
                "file": "The {attribute} must be less than or equal to {value} kilobytes.",
                "numeric": "The {attribute} must be less than or equal to {value}.",
                "string": "The {attribute} must be less than or equal to {value} characters."
            },
            "mac_address": "The {attribute} must be a valid MAC address.",
            "max": {
                "array": "The {attribute} must not have more than {max} items.",
                "file": "The {attribute} must not be greater than {max} kilobytes.",
                "numeric": "The {attribute} must not be greater than {max}.",
                "string": "The {attribute} must not be greater than {max} characters."
            },
            "max_digits": "The {attribute} field must not have more than {max} digits.",
            "mimes": "The {attribute} must be a file of type: {values}.",
            "mimetypes": "The {attribute} must be a file of type: {values}.",
            "min": {
                "array": "The {attribute} must have at least {min} items.",
                "file": "The {attribute} must be at least {min} kilobytes.",
                "numeric": "The {attribute} must be at least {min}.",
                "string": "The {attribute} must be at least {min} characters."
            },
            "min_digits": "The {attribute} field must have at least {min} digits.",
            "missing": "The {attribute} field must be missing.",
            "missing_if": "The {attribute} field must be missing when {other} is {value}.",
            "missing_unless": "The {attribute} field must be missing unless {other} is {value}.",
            "missing_with": "The {attribute} field must be missing when {values} is present.",
            "missing_with_all": "The {attribute} field must be missing when {values} are present.",
            "multiple_of": "The {attribute} must be a multiple of {value}.",
            "not_in": "The {attribute} field must not be in the list.",
            "not_regex": "The {attribute} format is invalid.",
            "numeric": "The {attribute} must be a number.",
            "password": {
                "letters": "The {attribute} field must contain at least one letter.",
                "mixed": "The {attribute} field must contain at least one uppercase and one lowercase letter.",
                "numbers": "The {attribute} field must contain at least one number.",
                "symbols": "The {attribute} field must contain at least one symbol.",
                "uncompromised": "The given {attribute} has appeared in a data leak. Please choose a different {attribute}."
            },
            "present": "The {attribute} field must be present.",
            "present_if": "The {attribute} field must be present when {other} is {value}.",
            "present_unless": "The {attribute} field must be present unless {other} is {value}.",
            "present_with": "The {attribute} field must be present when {values} is present.",
            "present_with_all": "The {attribute} field must be present when {values} are present.",
            "prohibited": "The {attribute} field is prohibited.",
            "prohibited_if": "The {attribute} field is prohibited when {other} is {value}.",
            "prohibited_unless": "The {attribute} field is prohibited unless {other} is in {values}.",
            "prohibits": "The {attribute} field prohibits {other} from being present.",
            "regex": "The {attribute} format is invalid.",
            "required": "The {attribute} field is required.",
            "required_array_keys": "The {attribute} field must contain entries for: {values}.",
            "required_if": "The {attribute} field is required when {other} is {value}.",
            "required_if_accepted": "The {attribute} field is required when {other} is accepted.",
            "required_if_declined": "The {attribute} field is required when {other} is declined.",
            "required_unless": "The {attribute} field is required unless {other} is in {values}.",
            "required_with": "The {attribute} field is required when {values} is present.",
            "required_with_all": "The {attribute} field is required when {values} are present.",
            "required_without": "The {attribute} field is required when {values} is not present.",
            "required_without_all": "The {attribute} field is required when none of {values} are present.",
            "same": "The {attribute} and {other} must match.",
            "size": {
                "array": "The {attribute} must contain {size} items.",
                "file": "The {attribute} must be {size} kilobytes.",
                "numeric": "The {attribute} must be {size}.",
                "string": "The {attribute} must be {size} characters."
            },
            "starts_with": "The {attribute} must start with one of the following: {values}.",
            "string": "The {attribute} must be a string.",
            "timezone": "The {attribute} must be a valid timezone.",
            "ulid": "The {attribute} field must be a valid ULID.",
            "unique": "The {attribute} has already been taken.",
            "uploaded": "The {attribute} failed to upload.",
            "uppercase": "The {attribute} field must be uppercase.",
            "url": "The {attribute} must be a valid URL.",
            "uuid": "The {attribute} must be a valid UUID.",
            "attributes": {
                "address": "address",
                "affiliate_url": "affiliate URL",
                "age": "age",
                "amount": "amount",
                "announcement": "announcement",
                "area": "area",
                "audience_prize": "audience prize",
                "available": "available",
                "billing_address.city": "city",
                "billing_address.country": "country",
                "billing_address.postcode": "postcode",
                "billing_address.street_name": "street name",
                "billing_address.street_number": "street number",
                "billing_address.street_number_addition": "street number addition",
                "birthday": "birthday",
                "body": "body",
                "city": "city",
                "compilation": "compilation",
                "concept": "concept",
                "conditions": "conditions",
                "consultant_id": "consultant",
                "content": "content",
                "country": "country",
                "cover": "cover",
                "created_at": "created at",
                "creator": "creator",
                "currency": "currency",
                "current_password": "current password",
                "customer": "customer",
                "date": "date",
                "date_of_birth": "date of birth",
                "dates": "dates",
                "day": "day",
                "deleted_at": "deleted at",
                "description": "description",
                "display_type": "display type",
                "district": "district",
                "duration": "duration",
                "email": "email",
                "excerpt": "excerpt",
                "filter": "filter",
                "finished_at": "finished at",
                "first_name": "first name",
                "gender": "gender",
                "grand_prize": "grand prize",
                "group": "group",
                "hour": "hour",
                "image": "image",
                "image_desktop": "desktop image",
                "image_main": "main image",
                "image_mobile": "mobile image",
                "images": "images",
                "is_audience_winner": "is audience winner",
                "is_hidden": "is hidden",
                "is_subscribed": "is subscribed",
                "is_visible": "is visible",
                "is_winner": "is winner",
                "items": "items",
                "key": "key",
                "last_name": "last name",
                "lesson": "lesson",
                "line_address_1": "line address 1",
                "line_address_2": "line address 2",
                "login": "login",
                "message": "message",
                "middle_name": "middle name",
                "minute": "minute",
                "mobile": "mobile",
                "month": "month",
                "name": "name",
                "national_code": "national code",
                "number": "number",
                "password": "password",
                "password_confirmation": "password confirmation",
                "phone": "phone",
                "photo": "photo",
                "portfolio": "portfolio",
                "postal_code": "postal code",
                "preview": "preview",
                "price": "price",
                "product_id": "product ID",
                "product_uid": "product UID",
                "product_uuid": "product UUID",
                "promo_code": "promo code",
                "province": "province",
                "quantity": "quantity",
                "reason": "reason",
                "recaptcha_response_field": "recaptcha response field",
                "referee": "referee",
                "referees": "referees",
                "reject_reason": "reject reason",
                "remember": "remember",
                "restored_at": "restored at",
                "result_text_under_image": "result text under image",
                "role": "role",
                "role_id": "role",
                "rule": "rule",
                "rules": "rules",
                "second": "second",
                "sex": "sex",
                "shipment": "shipment",
                "shipping_address.city": "city",
                "shipping_address.country": "country",
                "shipping_address.postcode": "postcode",
                "shipping_address.street_name": "street name",
                "shipping_address.street_number": "street number",
                "shipping_address.street_number_addition": "street number addition",
                "shipping_rate_id": "shipping rate",
                "short_text": "short text",
                "size": "size",
                "skills": "skills",
                "slug": "slug",
                "specialization": "specialization",
                "started_at": "started at",
                "state": "state",
                "status": "status",
                "street": "street",
                "student": "student",
                "subject": "subject",
                "tag": "tag",
                "tags": "tags",
                "teacher": "teacher",
                "terms": "terms",
                "test_description": "test description",
                "test_locale": "test locale",
                "test_name": "test name",
                "text": "text",
                "time": "time",
                "title": "title",
                "type": "type",
                "updated_at": "updated at",
                "user": "user",
                "username": "username",
                "value": "value",
                "year": "year"
            }
        },
        "routes": {
            "blog": "blog",
            "about-us": "about-us",
            "general-terms": "general-terms",
            "privacy-policy": "privacy-policy",
            "cookie-policy": "cookie-policy",
            "contact": "contact",
            "products": "products"
        },
        "actions": {
            "accept": "Accept",
            "action": "Action",
            "actions": "Actions",
            "add": "Add",
            "admin": "Admin",
            "agree": "Agree",
            "archive": "Archive",
            "assign": "Assign",
            "associate": "Associate",
            "attach": "Attach",
            "browse": "Browse",
            "cancel": "Cancel",
            "choose": "Choose",
            "choose_file": "Choose File",
            "choose_image": "Choose Image",
            "click_to_copy": "Click to copy",
            "close": "Close",
            "collapse": "Collapse",
            "collapse_all": "Collapse All",
            "comment": "Comment",
            "confirm": "Confirm",
            "connect": "Connect",
            "create": "Create",
            "delete": "Delete",
            "detach": "Detach",
            "details": "Details",
            "disable": "Disable",
            "discard": "Discard",
            "done": "Done",
            "down": "Down",
            "duplicate": "Duplicate",
            "edit": "Edit",
            "enable": "Enable",
            "expand": "Expand",
            "expand_all": "Expand All",
            "explanation": "Explanation",
            "export": "Export",
            "file": "File",
            "files": "Files",
            "go_home": "Go Home",
            "hide": "Hide",
            "home": "Home",
            "image": "Image",
            "impersonate": "Impersonate",
            "impersonation": "Impersonation",
            "import": "Import",
            "introduction": "Introduction",
            "like": "Like",
            "load": "Load",
            "localize": "Localize",
            "log_in": "Log In",
            "log_out": "Log Out",
            "named": {
                "add": "Add {name}",
                "choose": "Choose {name}",
                "create": "Create {name}",
                "delete": "Delete {name}",
                "duplicate": "Duplicate {name}",
                "edit": "Edit {name}",
                "hide": "Hide {name}",
                "import": "Import {name}",
                "new": "New {name}",
                "restore": "Restore {name}",
                "save": "Save {name}",
                "search": "Search {name}",
                "show": "Show {name}",
                "update": "Update {name}",
                "view": "View {name}"
            },
            "new": "New",
            "no": "No",
            "open": "Open",
            "open_website": "Open on the website",
            "preview": "Preview",
            "price": "Price",
            "record": "Record",
            "restore": "Restore",
            "save": "Save",
            "save_and_close": "Save & Close",
            "save_and_return": "Save & Return",
            "search": "Search",
            "select": "Select",
            "select_all": "Select All",
            "send": "Send",
            "settings": "Settings",
            "show": "Show",
            "show_all": "Show All",
            "sign_in": "Sign In",
            "solve": "Solve",
            "start": "Start",
            "stop": "Stop",
            "submit": "Submit",
            "subscribe": "Subscribe",
            "switch": "Switch",
            "switch_to_role": "Switch To Role",
            "tag": "Tag",
            "tags": "Tags",
            "target_link": {
                "blank": "Open in a new window",
                "parent": "Open in a parent frame",
                "self": "Open in a current window",
                "top": "Open in the topmost frame"
            },
            "translate": "Translate",
            "translate_it": "Translate It",
            "unpack": "Unpack",
            "unsubscribe": "Unsubscribe",
            "up": "Up",
            "update": "Update",
            "user": "User",
            "view": "View",
            "yes": "Yes"
        },
        "auth": {
            "failed": "These credentials do not match our records.",
            "password": "The password is incorrect.",
            "throttle": "Too many login attempts. Please try again in {seconds} seconds."
        },
        "pagination": {
            "next": "Next &raquo;",
            "previous": "&laquo; Previous"
        },
        "http-statuses": {
            "0": "Unknown Error",
            "100": "Continue",
            "101": "Switching Protocols",
            "102": "Processing",
            "200": "OK",
            "201": "Created",
            "202": "Accepted",
            "203": "Non-Authoritative Information",
            "204": "No Content",
            "205": "Reset Content",
            "206": "Partial Content",
            "207": "Multi-Status",
            "208": "Already Reported",
            "226": "IM Used",
            "300": "Multiple Choices",
            "301": "Moved Permanently",
            "302": "Found",
            "303": "See Other",
            "304": "Not Modified",
            "305": "Use Proxy",
            "307": "Temporary Redirect",
            "308": "Permanent Redirect",
            "400": "Bad Request",
            "401": "Unauthorized",
            "402": "Payment Required",
            "403": "Forbidden",
            "404": "Not Found",
            "405": "Method Not Allowed",
            "406": "Not Acceptable",
            "407": "Proxy Authentication Required",
            "408": "Request Timeout",
            "409": "Conflict",
            "410": "Gone",
            "411": "Length Required",
            "412": "Precondition Failed",
            "413": "Payload Too Large",
            "414": "URI Too Long",
            "415": "Unsupported Media Type",
            "416": "Range Not Satisfiable",
            "417": "Expectation Failed",
            "418": "I'm a teapot",
            "419": "Session Has Expired",
            "421": "Misdirected Request",
            "422": "Unprocessable Entity",
            "423": "Locked",
            "424": "Failed Dependency",
            "425": "Too Early",
            "426": "Upgrade Required",
            "428": "Precondition Required",
            "429": "Too Many Requests",
            "431": "Request Header Fields Too Large",
            "444": "Connection Closed Without Response",
            "449": "Retry With",
            "451": "Unavailable For Legal Reasons",
            "499": "Client Closed Request",
            "500": "Internal Server Error",
            "501": "Not Implemented",
            "502": "Bad Gateway",
            "503": "Maintenance Mode",
            "504": "Gateway Timeout",
            "505": "HTTP Version Not Supported",
            "506": "Variant Also Negotiates",
            "507": "Insufficient Storage",
            "508": "Loop Detected",
            "509": "Bandwidth Limit Exceeded",
            "510": "Not Extended",
            "511": "Network Authentication Required",
            "520": "Unknown Error",
            "521": "Web Server is Down",
            "522": "Connection Timed Out",
            "523": "Origin Is Unreachable",
            "524": "A Timeout Occurred",
            "525": "SSL Handshake Failed",
            "526": "Invalid SSL Certificate",
            "527": "Railgun Error",
            "598": "Network Read Timeout Error",
            "599": "Network Connect Timeout Error",
            "unknownError": "Unknown Error"
        },
        "passwords": {
            "reset": "Your password has been reset.",
            "sent": "We have emailed your password reset link.",
            "throttled": "Please wait before retrying.",
            "token": "This password reset token is invalid.",
            "user": "We can't find a user with that email address."
        }
    },
    "nl": {
        "(and {count} more error)": "(en {count} andere foutmelding)",
        "(and {count} more errors)": "(en {count} andere foutmelding)|(en {count} andere foutmeldingen)|(en {count} andere foutmeldingen)",
        "* Required fields": "* Verplichte velden",
        "*Required fields": "*Verplichte velden",
        "+": "+",
        "+1": "+1",
        "-": "-",
        "1 star": "1 ster",
        "2 stars": "2 sterren",
        "3 stars": "3 sterren",
        "4 stars": "4 sterren",
        "5 stars": "5 sterren",
        "404 - Page not found": "404 - Pagina niet gevonden",
        "404 Page": "404 Pagina",
        "404 Page settings": "Instellingen 404-pagina",
        "{Attribute} {lang}": "{Attribute} {lang}",
        "{Attribute} is invalid": "{Attribute} is ongeldig",
        "{count} characters": "{count} characters",
        "{model} {action}": "{model} {action}",
        "{variant} image": "{variant} afbeelding",
        "About HGBI": "Over HGBI",
        "About us": "Over ons",
        "Accept": "Aanvaarden",
        "Accepted": "Aanvaard",
        "Account": "Account",
        "Action": "Actie",
        "Actions": "Acties",
        "Activated": "Geactiveerd",
        "activated": "geactiveerd",
        "Active": "Actief",
        "Add": "Toevoegen",
        "Add {name}": "Voeg {name} toe",
        "Added product to cart": "Product toegevoegd aan winkelwagen",
        "Add menu item": "Menu-item toevoegen",
        "Add message": "Bericht toevoegen",
        "add one": "toevoegen",
        "Add page block": "Pagina blok toevoegen",
        "Add to favorites": "Toevoegen aan favorieten",
        "Add to shopping cart": "Toevoegen aan winkelwagentje",
        "Admin": "Beheerder",
        "Administrator": "Beheerder",
        "A form was submitted on the website.": "Er is een formulier ingediend op de website.",
        "Agree": "Akkoord",
        "All": "Alle",
        "All {models}": "Alle {modellen}",
        "All blogs": "Alle blogs",
        "All news (Including filter and pagination)": "Alle nieuws (inclusief filter en paginering)",
        "Allow cookies": "Cookies toestaan",
        "All Products": "Alle producten",
        "All products": "Alle producten",
        "All projects (Including filter and pagination)": "Alle projecten (inclusief filter en paginering)",
        "All questions": "Alle vragen",
        "All rights reserved.": "Alle rechten voorbehouden.",
        "All team members (Including pagination)": "Alle teamleden (inclusief paginering)",
        "All vacancies (Including filter and pagination)": "Alle vacatures (inclusief filter en paginering)",
        "Already Reported": "Al gemeld",
        "Alt text": "Alt-tekst",
        "Amount": "Bedrag",
        "Amount Blogs": "Bedrag Blogs",
        "Amount news": "Bedrag nieuws",
        "Amount of products": "Aantal producten",
        "Amount projects": "Bedrag projecten",
        "Amount team members": "Bedrag teamleden",
        "Amount vacancies": "Bedrag vacatures",
        "Answer": "Antwoord",
        "any": "enkele",
        "A piece": "Per stuk",
        "Apply discount code": "Kortingscode toepassen",
        "Apply now": "Nu solliciteren",
        "Archive": "Archiveren",
        "Assign": "Toewijzen",
        "Associate": "Associëren",
        "A Timeout Occurred": "Time-out opgetreden",
        "Attach": "Koppelen",
        "Author": "Auteur",
        "Back": "Terug",
        "Back and mark as done": "Terug en markeer als klaar",
        "Back and release": "Terug en loslaten",
        "Background color": "Achtergrondkleur",
        "Background image": "Achtergrondafbeelding",
        "Back to home": "Terug naar home",
        "Back to the shopping cart": "Terug naar winkelmand",
        "Backup codes": "Back-up codes",
        "Bad Gateway": "Slechte poort",
        "Bad Request": "Foute aanvraag",
        "Bandwidth Limit Exceeded": "Bandbreedte overschreden",
        "Banner": "Banner",
        "Banner (Slider Variant)": "Banner (schuifvariant)",
        "Beige": "Beige",
        "Billing address": "Factuuradres",
        "Birthday *": "Verjaardag *",
        "Black": "Zwart",
        "Blog": "Blog",
        "Blogs": "Blogs",
        "Blogs (Including filter and pagination)": "Blogs (inclusief filter en paginering)",
        "Blog Settings": "Blog Instellingen",
        "Blue": "Blauw",
        "Blue Button": "Blauwe knop",
        "Brass": "Messing",
        "Bronze": "Brons",
        "Brown": "Bruin",
        "Browse": "Bladeren",
        "BTW number": "BTW-nummer",
        "Bullet point": "Bullet point",
        "Bullet points": "Bullet points",
        "Button label": "Knoplabel",
        "Button link": "Knop link",
        "Buttons": "Knoppen",
        "Button text": "Knoptekst",
        "By subscribing you agree to receive our newsletter.": "Door je in te schrijven ga je akkoord met het ontvangen van onze nieuwsbrief.",
        "Cancel": "Annuleren",
        "Categories": "Categorieën",
        "Category": "Categorie",
        "Category Settings": "Categorie Instellingen",
        "Category slider": "Categorie slider",
        "Change": "Verander",
        "Change password": "Wachtwoord wijzigen",
        "Checkout": "Kassa",
        "Checkout page": "Afrekenpagina",
        "Choose": "Kiezen",
        "Choose {name}": "Kies {name}",
        "Choose File": "Bestand kiezen",
        "Choose file": "Kies bestand",
        "Choose Image": "Kies afbeelding",
        "Choose link type": "Type link kiezen",
        "City": "Stad",
        "Click me!": "Klik op mij!",
        "Click to copy": "Klik om te kopiëren",
        "Client": "Cliënt",
        "Client Closed Request": "Klant Gesloten Verzoek",
        "Clients": "Cliënten",
        "Close": "Sluit",
        "Close conversation": "sluit gesprek",
        "closed": "gesloten",
        "CoC": "KvK",
        "Coc:": "KvK",
        "Coc number": "Kvk nummer",
        "Code": "Code",
        "code": "code",
        "Collapse": "Inklappen",
        "Collapse All": "Alles inklappen",
        "Color": "Kleur",
        "Colour": "Kleur",
        "Colour code": "Kleurcode",
        "Colour name": "Kleur naam",
        "Comment": "Reageren",
        "Company details": "Bedrijfsgegevens",
        "Company name": "Bedrijfsnaam",
        "Company Name: {company_name}": "Bedrijfsnaam: {company_name}",
        "Complete": "Compleet",
        "Concept in only visible to logged in users, draft is not visible to anyone": "Concept in alleen zichtbaar voor ingelogde gebruikers, draft is voor niemand zichtbaar",
        "Conditional Page link": "Voorwaardelijke paginalink",
        "Confirm": "Bevestig",
        "Confirm Password": "Wachtwoord bevestigen",
        "Confirm password": "Wachtwoord bevestigen",
        "Conflict": "Conflict",
        "Connect": "Aansluiten",
        "Connection Closed Without Response": "Verbinding gesloten zonder reactie",
        "Connection Timed Out": "Connectie duurt te lang",
        "Consultant": "Consultant",
        "Contact": "Contact",
        "Contact details": "Contactgegevens",
        "Contact form": "Contactformulier",
        "Contact information": "Contactinformatie",
        "Content": "Inhoud",
        "Content (Collapsible)": "Inhoud (Inklapbaar)",
        "Continue": "Doorgaan",
        "Conversation": "Gesprek",
        "Conversations": "Gesprekken",
        "Copper": "Koper",
        "Counter": "Tegen",
        "Counter number": "Teller",
        "Counters": "Tellers",
        "Counter Short description": "Teller Korte beschrijving",
        "Counter title": "Titel",
        "Coupon": "Coupon",
        "Coupon applied": "Coupon toegepast",
        "Coupon can no longer be used": "Coupon kan niet langer worden gebruikt",
        "Coupon not found": "Coupon niet gevonden",
        "Coupons": "Coupons",
        "Create": "Aanmaken",
        "create": "maak aan",
        "Create {model}": "Maak {model}",
        "Create {name}": "Creëer {name}",
        "Create an account": "Een account aanmaken",
        "Create a password": "Maak een wachtwoord aan",
        "Created": "Aangemaakt",
        "created": "aangemaakt",
        "Created at": "Gemaakt op",
        "Create follow-up": "Follow-up creëren",
        "Create order": "Bestel",
        "Currently no stock": "Momenteel geen voorraad",
        "Current Password": "Huidig wachtwoord",
        "Custom": "Aangepast",
        "Custom background color": "Aangepaste achtergrondkleur",
        "Customers will be shown the cheapest applicable shipping rate from each group. Currently existing groups: {groups}": "Klanten krijgen de goedkoopste toepasselijke verzendkosten van elke groep te zien. Momenteel bestaande groepen: {groups}",
        "Custom text color": "Aangepaste tekstkleur",
        "Custom title": "Aangepaste titel",
        "Dangerously high": "Gevaarlijk hoog",
        "Dangerously high explanation": "Gevaarlijk hoge uitleg",
        "Dangerously Low": "Gevaarlijk laag",
        "Dangerously low explanation": "Gevaarlijk lage verklaring",
        "Dashboard": "Dashboard",
        "Date": "Datum",
        "Date: {date}": "Datum: {date}",
        "Date of birth": "Geboortedatum",
        "Deactivate": "Deactiveer",
        "Deactivated": "gedeactiveerd",
        "deactivated": "gedeactiveerd",
        "Decline cookies": "Cookies weigeren",
        "Default consultant": "Standaard consulent",
        "De laatste trends": "De laatste trends",
        "Delete": "Verwijder",
        "delete": "verwijder",
        "Delete {name}": "Verwijder {name}",
        "deleted": "verwijderd",
        "Delete filters": "Filters verwijderen",
        "Delivery options": "Bezorgopties",
        "Delivery or pick up?": "Bezorg of afhalen?",
        "Description": "Beschrijving",
        "description": "beschrijving",
        "Desktop": "Desktop",
        "Detach": "Losmaken",
        "Details": "Informatie",
        "Disable": "Schakel uit",
        "Disable swiper": "Swiper uitschakelen",
        "Disabling a coupon will prevent it from being used": "Als je een coupon uitschakelt, kan deze niet meer worden gebruikt.",
        "Discard": "Weggooien",
        "Disclaimer": "Disclaimer",
        "Discount": "Korting",
        "Discount active": "Korting actief",
        "Discount code": "Kortingscode",
        "Done": "Klaar",
        "done": "klaar",
        "Down": "Omlaag",
        "Download app": "App downloaden",
        "Download backup codes and continue.": "Download back-up codes en ga verder.",
        "Download invoice": "Factuur downloaden",
        "Download PDF button name": "Knopnaam PDF downloaden",
        "Due at": "Toe doen op",
        "Duplicate": "Dupliceren",
        "Duplicate {name}": "Dupliceer: naam",
        "E-mail": "E-mail",
        "E-mail *": "E-mail *",
        "Edit": "Aanpassen",
        "edit": "bewerk",
        "Edit {model}": "{model} bewerken",
        "Edit {name}": "Bewerk {name}",
        "Edit account": "Account bewerken",
        "Email": "E-mail",
        "email": "e-mail",
        "Email: {email}": "E-mail: {email}",
        "Email address": "E-mailadres",
        "Employees": "Werknemers",
        "Employers": "Werkgevers",
        "employers": "werkgevers",
        "Enable": "Schakel in",
        "End": "Einde",
        "errors": "fouten",
        "Excerpt": "Uittreksel",
        "excerpt": "uittreksel",
        "Expand": "Uitvouwen",
        "Expand All": "Alles uitvouwen",
        "Expectation Failed": "Niet voldaan aan verwachting",
        "Explanation": "Uitleg",
        "Export": "Exporteren",
        "External Link": "Externe link",
        "External Test": "Externe test",
        "External Tester": "Extern testapparaat",
        "External URL": "Externe URL",
        "Facebook": "Facebook",
        "Facebook link": "Facebook-link",
        "Failed Dependency": "Gefaalde afhankelijkheid",
        "Faq": "Faq",
        "Faqoverview": "Faqoverzicht",
        "Faqs": "Vragen",
        "Fast delivery": "Snelle levering",
        "Favorites": "Favorieten",
        "favorites": "Favorieten",
        "Featured Image": "Uitgelichte afbeelding",
        "Female": "Vrouw",
        "File": "Bestand",
        "Files": "Bestanden",
        "Fill in the 6-digit code": "Vul de 6-cijferige code in",
        "Filter": "Filter",
        "Filters": "Filters",
        "First name": "Voornaam",
        "First name *": "Voornaam *",
        "First things first": "First things first",
        "Fixed": "Vast",
        "Fixed Cta": "Vaste Cta",
        "Focal point": "Brandpunt",
        "Follow up to": "Vervolg op",
        "Follow up tot": "Opvolging tot",
        "Follow us": "Volg ons",
        "Follow us on": "Volg ons op",
        "Forbidden": "Geen toegang",
        "Forgot password": "Wachtwoord vergeten",
        "Forgot password?": "Wachtwoord vergeten?",
        "Forgot your password? Send a password reset link": "Wachtwoord vergeten? Stuur een wachtwoord reset link",
        "Form submission": "Formulier indienen",
        "Form submission on {app_name}": "Formulier ingediend op {app_name}",
        "Form submissions": "Formulieren",
        "Found": "Gevonden",
        "Free": "Gratis",
        "FREE SHIPPING WITH ORDERS OVER €75": "GRATIS VERZENDING BIJ BESTELLINGEN BOVEN €75",
        "Frequently Asked Questions": "Veelgestelde vragen",
        "Frequently asked questions": "Veelgestelde vragen",
        "From the authenticator app": "Vanuit de authenticator app",
        "Gain muscle product": "Spierversterkend product",
        "Gallery": "Galerie",
        "Gateway Timeout": "Gateway-time-out",
        "General slider": "Algemene slider",
        "Generate variants": "Varianten genereren",
        "Goal": "Doel",
        "Go Home": "Terug naar de voorpagina",
        "Gold": "Goud",
        "Gone": "Verdwenen",
        "Google Authenticator": "Google Authenticator",
        "Google Maps": "Google-kaarten",
        "Google Maps Iframe": "Google Maps Iframe",
        "Google Maps URL": "Google Maps URL",
        "Google maps URL": "Google maps URL",
        "Go to page {page}": "Ga naar pagina {page}",
        "GRATIS VERZENDING BIJ BESTEDING VAN € 75,-": "GRATIS VERZENDING BIJ BESTEDING VAN € 75,-",
        "Green": "Groen",
        "Grey": "Grijs",
        "Group": "Groep",
        "Has image": "Heeft afbeelding",
        "Heading": "Rubriek",
        "Hello": "Hallo",
        "Hello!": "Hallo!",
        "Hide": "Verbergen",
        "Hide {name}": "Verberg {name}",
        "Hide on page": "Verbergen op pagina",
        "High": "Hoog",
        "Highest": "Hoogste",
        "Highest quality": "Hoogste kwaliteit",
        "High explanation": "Hoge uitleg",
        "Hits": "Hits",
        "Home": "Startpagina",
        "Hoogste kwaliteit": "Hoogste kwaliteit",
        "hour": "uur",
        "HTTP Version Not Supported": "HTTP-versie wordt niet ondersteund",
        "I'm a teapot": "Ik ben een theepot",
        "I agree with the": "Ik ben het eens met de",
        "I am new here": "Ik ben nieuw hier",
        "Icon": "Pictogram",
        "Icon alt text": "Pictogram alt-tekst",
        "Icon title": "Titel pictogram",
        "If checked, the image will be full width.": "Als deze optie is aangevinkt, wordt de afbeelding op volledige breedte weergegeven.",
        "If checked, the image will have a gradient. Only has effect if \"Image full width\" is checked.": "Als deze optie is aangevinkt, heeft de afbeelding een kleurovergang. Heeft alleen effect als \"Afbeelding volledige breedte\" is aangevinkt.",
        "If enabled, and no image is uploaded, the banner from the homepage will be shown.": "Indien ingeschakeld, en er is geen afbeelding geupload, dan wordt de banner van de homepage getoond.",
        "If left empty, the page title will be used.": "Als deze leeg blijft, wordt de paginatitel gebruikt.",
        "If left empty, the title will be used.": "Als deze leeg blijft, wordt de titel gebruikt.",
        "If you're having trouble clicking the \"{actionText}\" button, copy and paste the URL belowninto your web browser:": "Als je problemen hebt met de \"{actionText}\" knop, kopieer en plak de URL hierondernin je webbrowser:",
        "If you did not create an account, no further action is required.": "Als je geen account hebt aangemaakt hoef je verder niets te doen.",
        "If you did not request a password reset, no further action is required.": "Als je geen wachtwoordherstel hebt aangevraagd, hoef je verder niets te doen.",
        "I have saved the backup codes": "Ik heb de back-up codes opgeslagen",
        "Image": "Afbeelding",
        "Image CTA": "Afbeelding CTA",
        "Image full width": "Afbeelding volledige breedte",
        "Images": "Afbeeldingen",
        "Impersonate": "Voordoen als een ander",
        "Impersonation": "Imitatie",
        "Import": "Importeren",
        "Import {name}": "Importeren {name}",
        "IM Used": "Ik ben gebruikt",
        "Including VAT": "Inclusief BTW",
        "Index Page": "Indexpagina",
        "Inloggen": "Inloggen",
        "In shoppingcart": "In winkelwagen",
        "Inspection": "Inspectie",
        "inspiration guide.": "inspiratiegids.",
        "Instagram": "Instagram",
        "Instagram link": "Instagram-link",
        "Instruction title": "Titel van de instructie",
        "Insufficient Storage": "Onvoldoende opslag",
        "Internal comment": "Interne opmerking",
        "Internal comments": "Interne opmerkingen",
        "Internal remark": "Interne opmerking",
        "Internal Server Error": "Interne serverfout",
        "Introduction": "Invoering",
        "Invalid JSON was returned from the route.": "Er is ongeldige JSON teruggekomen van de route.",
        "Invalid phone number": "Ongeldig telefoonnummer",
        "Invalid SSL Certificate": "Ongeldig SSL-certificaat",
        "It is recommended to keep the {attribute} between {min} and {max} characters long": "Het wordt aanbevolen het {attribute} tussen {min} en {max} tekens lang te houden.",
        "Job position": "Functie",
        "Kind regards,": "Met vriendelijke groet,",
        "KVK number": "KVK-nummer",
        "Label": "Label",
        "Label Button": "Labelknop",
        "Language": "Taal",
        "Large": "Groot",
        "Last message on": "Laatste bericht op",
        "Last name": "Achternaam",
        "Last name *": "Achternaam *",
        "Last refresh was {quantity} {unit} ago": "Laaste verversing was {quantity} {unit} geleden",
        "Leave empty for no end date": "Leeg laten voor geen einddatum",
        "Leave empty to generate a random code. Not editable after creation.": "Leeg laten om een willekeurige code te genereren. Kan niet worden bewerkt na aanmaak.",
        "Leave empty to use 'Related products'": "Leeg laten om 'Gerelateerde producten' te gebruiken",
        "Leave empty to use parent details": "Leeg laten om hoofdgegevens te gebruiken",
        "Length Required": "Lengte vereist",
        "Like": "Leuk vinden",
        "Link Button": "Koppelingsknop",
        "Linkedin link": "Linkedin link",
        "Link text": "Link tekst",
        "Load": "Laden",
        "Localize": "Lokaliseren",
        "Location": "Plaats",
        "Location: {location}": "Locatie: {location}",
        "Locked": "Afgesloten",
        "Log In": "Inloggen",
        "Login": "Inloggen",
        "Login / Register": "Inloggen / Registreren",
        "Login to your account in our secure environment": "Log in op je account in onze beveiligde omgeving",
        "Logo slider": "Logo schuifbalk",
        "Log Out": "Uitloggen",
        "Logout": "Uitloggen",
        "Loop Detected": "Loop gedetecteerd",
        "Lose weight product": "Product om af te vallen",
        "Low": "Laag",
        "Lowest": "Laagste",
        "Low explanation": "Lage uitleg",
        "Loyal customer": "Trouwe klant",
        "Made by:": "Gerealiseerd door:",
        "Maintain muscle product": "Spierproduct onderhouden",
        "Maintenance Mode": "Dienst niet beschikbaar",
        "Main variant": "Hoofdvariant",
        "Make main": "Hoofdvariant maken",
        "Make your decision:": "Neem een beslissing:",
        "Male": "Man",
        "Mark as done": "Markeren als gedaan",
        "Max users": "Maximaal aantal gebruikers",
        "Medical": "Medisch",
        "Medium": "Medium",
        "Meer informatie": "Meer informatie",
        "Menu": "Menu",
        "Menucard": "Menucard",
        "Menu items": "Menu-items",
        "Menus": "Menu's",
        "Message": "Bericht",
        "Message *": "Bericht *",
        "Message: {message}": "Bericht: {message}",
        "Messages": "Berichten",
        "Method Not Allowed": "Methode niet toegestaan",
        "Metric Name": "Meetwaarden naam",
        "Microsoft Authenticator": "Microsoft Authenticator",
        "Minimal order amount": "Minimaal bestelbedrag",
        "minus icon": "minpictogram",
        "minute": "minuut",
        "minutes": "minuten",
        "Misdirected Request": "Verkeerd geadresseerd verzoek",
        "Mobile": "Mobiel",
        "More": "Meer",
        "More information": "Meer informatie",
        "More settings can be set in the Page Block Settings page": "Meer instellingen kunnen worden ingesteld op de pagina Instellingen Paginablokken",
        "Most sold": "Meest verkocht",
        "Moved Permanently": "Definitief verplaatst",
        "Multi-Status": "Meerdere statussen",
        "Multiple Choices": "Meerkeuze",
        "Mute client": "Mute client",
        "Muted": "Gedempt",
        "muted": "gedempt",
        "Name": "Naam",
        "name": "naam",
        "Name *": "Naam *",
        "Name: {name}": "Naam: {name}",
        "Name client": "Naam cliënt",
        "Need inspiration in advance? Browse our": "Alvast inspiratie nodig? Blader door onze",
        "Network Authentication Required": "Netwerkauthenticatie vereist",
        "Network Connect Timeout Error": "Fout bij time-out netwerkverbinding",
        "Network Read Timeout Error": "Time-outfout netwerk lezen",
        "New": "Nieuwe",
        "New {name}": "Nieuw {name}",
        "Newest": "Nieuwste",
        "New Password": "Nieuw wachtwoord",
        "News": "Nieuws",
        "Newsletter": "Nieuwsbrief",
        "New URL": "Nieuwe URL",
        "Next": "Volgende",
        "No": "Nee",
        "No Content": "Geen inhoud",
        "No favorites yet": "Nog geen favorieten",
        "No items": "Geen items",
        "Non-Authoritative Information": "Niet-gemachtigde informatie",
        "None": "Geen",
        "No options have been created yet": "Er zijn nog geen opties gemaakt",
        "No orders yet": "Nog geen bestellingen",
        "No products found. Try again": "Geen producten gevonden. Opnieuw proberen",
        "No results found": "Geen resultaten gevonden",
        "No settings available": "Geen instellingen beschikbaar",
        "Not Acceptable": "Niet aanvaardbaar",
        "Not available": "Niet beschikbaar",
        "Not available in this combination": "Niet beschikbaar in deze combinatie",
        "Not editable after creation.": "Niet bewerkbaar na aanmaak.",
        "Not Extended": "Niet verlengd",
        "Not Found": "Niet gevonden",
        "Not Implemented": "Niet geïmplementeerd",
        "Not implemented yet": "Nog niet geïmplementeerd",
        "Not Modified": "Niet gewijzigd",
        "Number": "Aantal",
        "of": "van",
        "OK": "Oké",
        "Old URL": "Oude URL",
        "On your phone or tablet with camera. We can advise you to use {google} or {microsoft}.": "Op uw telefoon of tablet met camera. Wij kunnen u adviseren om {google} of {microsoft} te gebruiken.",
        "Open": "Open",
        "Open in a current window": "Open in een huidig ​​venster",
        "Open in a new window": "Open in een nieuw venster",
        "Open in a parent frame": "Open in een bovenliggend frame",
        "Opening hours": "Openingstijden",
        "Openings hours": "Openingstijden",
        "Open in new tab": "In nieuw tabblad openen",
        "Open in the topmost frame": "Open in het bovenste frame",
        "Open on the website": "Open op de website",
        "Open the authenticator app, add a new entry and scan the QR code": "Open de authenticator-app, voeg een nieuw item toe en scan de QR-code",
        "Options": "Opties",
        "Orange": "Oranje",
        "Orange Button": "Oranje knop",
        "Order": "Bestel",
        "Order now": "Bestel nu",
        "Order number": "Bestelnummer",
        "Orders": "Bestellingen",
        "Order was created": "Order is aangemaakt",
        "Origin Is Unreachable": "Herkomst is onbereikbaar",
        "Overview": "Overzicht",
        "Package name": "Naam verpakking",
        "Packages": "Pakketten",
        "Page": "Pagina",
        "Page (within website)": "Pagina (binnen website)",
        "Page blocks": "Pagina blokken",
        "Page block settings": "Instellingen Paginablokken",
        "Page button": "Pagina knop",
        "Page Content": "Pagina-inhoud",
        "Page Expired": "Pagina niet meer geldig",
        "Page Header": "Pagina kop",
        "Page link": "Pagina link",
        "Pages": "Pagina's",
        "Page Title": "Pagina Titel",
        "Pagination Navigation": "Paginanavigatie",
        "Paragraph": "Paragraaf",
        "Parent category": "Hoofdcategorie",
        "Partial Content": "Gedeeltelijke inhoud",
        "Password": "Wachtwoord",
        "Password reset": "Wachtwoord opnieuw instellen",
        "Password reset link has been sent": "Resetlink voor wachtwoord is verzonden",
        "Paste or type the code we sent a code to your e-mail.": "Plak of typ de code die we naar je e-mail hebben gestuurd.",
        "Paste or type the code we sent a code to your phone number.": "Plak of typ de code die we naar je telefoonnummer hebben gestuurd.",
        "Payload Too Large": "Aanvraag te groot",
        "Payment": "Betaling",
        "Payment Required": "Betaling Vereist",
        "Payments": "Betalingen",
        "PDF Menu cards": "PDF Menukaarten",
        "People": "Mensen",
        "Percentage": "Percentage",
        "Perfecte pasvorm": "Perfecte pasvorm",
        "Perfect fit": "Perfecte pasvorm",
        "Permanent": "Permanent",
        "Permanent Redirect": "Definitieve omleiding",
        "Phone": "Telefoon",
        "Phone *": "Telefoon *",
        "Phone: {phone}": "Telefoon: {phone}",
        "Phone number": "Telefoonnummer",
        "phone number": "telefoonnummer",
        "phone_number": "telefoonnummer",
        "Pick-up": "Afhalen",
        "Pink": "Roze",
        "Place": "Plaats",
        "Place order": "Bestelling plaatsen",
        "Please click the button below to verify your email address.": "Klik op de knop hieronder om je e-mailadres te verifiëren.",
        "Please use the link in your email to view your order.": "Gebruik de link in je e-mail om je bestelling te bekijken.",
        "plus icon": "plus icoon",
        "Postal code": "Postcode",
        "Postcode": "Postcode",
        "Precondition Failed": "Niet voldaan aan de vooraf gestelde voorwaarde",
        "Precondition Required": "Voorwaarde nodig",
        "Premium quality": "Premium kwaliteit",
        "Preview": "Voorvertoning",
        "Previous": "Vorige",
        "Price": "Prijs",
        "Price (high to low)": "Prijs (hoog naar laag)",
        "Price (low to high)": "Prijs (laag naar hoog)",
        "Price: High - Low": "Prijs: Hoog - Laag",
        "Price: Low - High": "Prijs: Laag - Hoog",
        "Price frequency": "Prijsfrequentie",
        "Primary": "Primair",
        "Print": "Afdrukken",
        "Priority": "Prioriteit",
        "Privacy declaration": "Privacyverklaring",
        "Privacy page": "Privacy pagina",
        "Processing": "Verwerken",
        "Product": "Product",
        "Product details": "Productgegevens",
        "Product information": "Productinformatie",
        "Product option": "Product optie",
        "Product options": "Productopties",
        "Products": "Producten",
        "products": "Producten",
        "Product Settings": "Productinstellingen",
        "Product slider": "Product slider",
        "Projects": "Projecten",
        "Proxy Authentication Required": "Authenticatie op de proxyserver verplicht",
        "Publish date": "Datum publicatie",
        "Published At": "Gepubliceerd op",
        "Published from": "Gepubliceerd vanaf",
        "Published until": "Gepubliceerd tot",
        "Purple": "Paars",
        "Quantity": "Aantal",
        "Question": "Vraag",
        "Quote": "Citaat",
        "Railgun Error": "Railgun foutmelding",
        "Randimize image": "Randimaliseer afbeelding",
        "Randomize image": "Afbeelding kleuren willekeurig maken",
        "Range Not Satisfiable": "Bereik niet bevredigend",
        "Reactivate": "Reactiveren",
        "Read more": "Meer lezen",
        "Recommended products": "Aanbevolen producten",
        "Record": "Dossier",
        "Red": "Rood",
        "Redirect": "omleiden",
        "Redirects": "Omleidingen",
        "Regards": "Met vriendelijke groet",
        "Register": "Registreren",
        "Register to your account in our secure environment": "Registreer je account in onze beveiligde omgeving",
        "Related products": "Gerelateerde producten",
        "Remove": "Verwijder",
        "Remove discount code": "Kortingscode verwijderen",
        "Remove from favorites": "Verwijderen uit favorieten",
        "remove one": "verwijderen",
        "remove product": "product verwijderen",
        "Remove product from cart": "Product uit winkelwagentje verwijderen",
        "Reopened subtask": "Heropende subtaak",
        "Request Header Fields Too Large": "Headers van de aanvraag te lang",
        "Request Timeout": "Aanvraagtijd verstreken",
        "Resend {attribute}": "Verstuur {attribute} opnieuw",
        "Reserved By": "Gereserveerd door",
        "Reset Content": "Inhoud opnieuw instellen",
        "Reset Password": "Wachtwoord herstellen",
        "Reset password": "Wachtwoord opnieuw instellen",
        "Reset Password Notification": "Wachtwoordherstel notificatie",
        "Restore": "Herstel",
        "Restore {name}": "Herstel {name}",
        "Results": "Resultaten",
        "results": "resultaten",
        "Results are shared": "De resultaten worden gedeeld",
        "Retry With": "Opnieuw proberen met",
        "Review": "Beoordeling",
        "Reviews": "Beoordelingen",
        "Review slider": "Beoordelingsschuif",
        "RGB color": "RGB-kleur",
        "Role": "Rol",
        "role": "rol",
        "Roles": "Rollen",
        "Sales": "Verkoop",
        "Sampled at": "Afgenomen op",
        "Save": "Opslaan",
        "Save & Close": "Opslaan en sluiten",
        "Save & Return": "Opslaan en teruggaan",
        "Save {name}": "Sla {name} op",
        "Save in favorites": "Opslaan in favorieten",
        "Save these codes on a secure place if you can’t get access with your Authenticator app.": "Bewaar deze codes op een veilige plek als je geen toegang krijgt met je Authenticator app.",
        "Scanning not possible? Fill in this code in the app.": "Scannen niet mogelijk? Vul deze code in de app in.",
        "Scan the QR code": "Scan de QR-code",
        "Search": "Zoek",
        "Search {name}": "Zoek {name}",
        "Secondary": "Secundair",
        "Secure payment": "Veilige betaling",
        "See all faqs": "Alle vragen bekijken",
        "See Other": "Zie andere",
        "Select": "Selecteer",
        "Select a {model}": "Selecteer een {model}",
        "Select All": "Selecteer alles",
        "Select a page": "Selecteer een pagina",
        "Select a page or leave empty and use external url.": "Selecteer een pagina of laat deze leeg en gebruik een externe url.",
        "Select a template to add the corresponding blocks.": "Selecteer een sjabloon om de bijbehorende blokken toe te voegen.",
        "Select a template to add the corresponding blocks. Note that this cannot be changed after page has been created": "Selecteer een template om de bijbehorende blokken toe te voegen. Let op: dit kan niet worden gewijzigd nadat de pagina is aangemaakt.",
        "Select a test": "Selecteer een test",
        "Select a type": "Selecteer een type",
        "Selection aid": "Keuzehulp",
        "selection aid": "keuzehulp",
        "Send": "Versturen",
        "Send and return": "Verzenden en retourneren",
        "Send a password reset link": "Stuur een link om het wachtwoord te resetten",
        "Send application": "Aanvraag verzenden",
        "Send email": "Stuur e-mail",
        "Send link": "Link verzenden",
        "Seo Settings": "Seo-instellingen",
        "SEO Settings for {lang}": "SEO instellingen voor {lang}",
        "SEO title": "SEO titel",
        "Server Error": "Server fout",
        "Service": "Service",
        "Services": "Diensten",
        "Service Unavailable": "Website onbeschikbaar",
        "Session Has Expired": "Pagina verlopen",
        "Sets": "Zet",
        "Settings": "Instellingen",
        "Set Two Factor Authentication": "Twee Factor Authenticatie instellen",
        "Sex": "Geslacht",
        "shared": "gedeeld",
        "Share results": "Resultaten delen",
        "Shipping address": "Verzendadres",
        "Shipping address same as billing address": "Verzendadres hetzelfde als factuuradres",
        "Shipping and billing address": "Verzend- & factuuradres",
        "Shipping and return information": "Informatie over verzenden en retourneren",
        "Shipping cost": "Verzendkosten",
        "Shipping method": "Verzendmethode",
        "Shipping Rate": "Verzendtarief",
        "shipping rate": "verzendtarief",
        "Shipping Rates": "Verzendtarieven",
        "Shop": "Winkel",
        "Shopping cart": "Winkelwagen",
        "shopping cart": "winkelwagen",
        "Shopping cart page": "Pagina met winkelwagentje",
        "Short variant": "Korte variant",
        "Show": "Toon",
        "Show {name}": "Toon {name}",
        "Show All": "Toon alles",
        "Showing": "Toont",
        "Show less": "Minder tonen",
        "Show more": "Meer tonen",
        "Show products": "Producten tonen",
        "Show rectangle": "Rechthoek weergeven",
        "sidebar": "zijbalk",
        "Sign In": "Aanmelden",
        "Sign out": "Afmelden",
        "Sign up for our newsletter": "Schrijf je in voor onze nieuwsbrief",
        "Silver": "Zilver",
        "Size chart image": "Afbeelding maattabel",
        "Slides": "Dia's",
        "Slug": "Permalink",
        "Small": "Klein",
        "Social media": "Sociale media",
        "Solve": "Oplossen",
        "Sort": "Sorteer",
        "Split read more": "Gesplitst lees meer",
        "SSL Handshake Failed": "SSL-handshake mislukt",
        "Standard": "Standaard",
        "Stars": "Sterren",
        "Start": "Begin",
        "Start date:": "Startdatum:",
        "Started at": "Gestart op",
        "Status": "Status",
        "Statuses": "Statussen",
        "Stock": "Voorraad",
        "Stop": "Stop",
        "Street name": "Straatnaam",
        "Street number": "Huisnummer",
        "Street number addition": "Toevoeging",
        "Subject": "Onderwerp",
        "Subject *": "Onderwerp *",
        "Sub menu": "Submenu",
        "Submit": "Indienen",
        "Subscribe": "Aanmelden",
        "SubTasks": "Subtaken",
        "Subtitle": "Ondertitel",
        "subtitle": "ondertitel",
        "Subtotal": "Subtotaal",
        "Suitable Goals": "Geschikte doelen",
        "Switch": "Schakelaar",
        "Switching Protocols": "Protocolwissel",
        "Switch To Role": "Schakel over naar rol",
        "Tag": "Label",
        "Tags": "Labels",
        "Task": "Taak",
        "Tasks": "Taken",
        "Team": "Team",
        "Tel": "Tel",
        "Temporary Redirect": "Tijdelijke omleiding",
        "Terms and Conditions": "Algemene Voorwaarden",
        "terms and conditions": "algemene voorwaarden",
        "Terms page": "Voorwaarden pagina",
        "Tertiary": "Tertiair",
        "Test": "Test",
        "test": "test",
        "Tests": "Tests",
        "Test set": "Testreeks",
        "test set": "testreeks",
        "Test sets": "Testreeksen",
        "Text": "Tekst",
        "Thanks for your message, we will contact you as soon as possible.": "Bedankt voor je bericht, we nemen zo snel mogelijk contact met je op.",
        "Thank you for contacting us!": "Bedankt dat je contact met ons hebt opgenomen!",
        "Thank you for shopping with us!": "Bedankt voor het winkelen bij ons!",
        "Thank you for your application!": "Bedankt voor je sollicitatie!",
        "Thank you for your message!": "Bedankt voor je bericht!",
        "Thank you for your message.": "Bedankt voor je bericht.",
        "Thank you for your sign up!": "Bedankt voor je aanmelding!",
        "Thank you page settings": "Instellingen bedankpagina",
        "The {attribute} has already been taken.": "Het {attribute} is al ingenomen.",
        "The {attribute} must be verified.": "Het {attribute} moet worden geverifieerd.",
        "The {attribute} must contain at least one letter.": "Het {attribute} moet minimaal één letter bevatten.",
        "The {attribute} must contain at least one number.": "Het {attribute} moet minimaal één cijfer bevatten.",
        "The {attribute} must contain at least one symbol.": "Het {attribute} moet minimaal één symbool bevatten.",
        "The {attribute} must contain at least one uppercase and one lowercase letter.": "Het {attribute} moet minimaal één hoofdletter en één kleine letter bevatten.",
        "The coupon has already been used.": "De coupon is al gebruikt.",
        "The coupon has expired.": "De coupon is verlopen.",
        "The given {attribute} has appeared in a data leak. Please choose a different {attribute}.": "Het {attribute} is aangetroffen in een datalek. Geef een ander {attribute}.",
        "The given data was invalid.": "De gegeven data was ongeldig.",
        "The latest trends": "De nieuwste trends",
        "The password field is required when creating an account.": "Het wachtwoordveld is vereist bij het aanmaken van een account.",
        "The provided {attribute} is invalid.": "Het verstrekte {attribute} is ongeldig.",
        "The provided code was invalid.": "De verstrekte code was ongeldig.",
        "The provided password does not match your current password.": "Het opgegeven wachtwoord komt niet overeen met uw huidige wachtwoord.",
        "The provided two factor authentication code was invalid.": "De verstrekte tweefactorauthenticatiecode was ongeldig.",
        "The response is not a streamed response.": "De respons is niet gestreamd.",
        "The response is not a view.": "De respons is geen view.",
        "The tests cannot be changed once the test set is created.": "De tests kunnen niet worden gewijzigd nadat de testreeks is aangemaakt.",
        "This {attribute} has already been used": "Dit {attribute} is al gebruikt",
        "This action is unauthorized.": "Deze actie is niet toegestaan.",
        "This area is restricted to medical medic members only.": "Dit gebied is alleen toegankelijk voor medische leden.",
        "This area is restricted to staff members only.": "Dit gebied is alleen toegankelijk voor personeelsleden.",
        "This is your first login": "Dit is uw eerste login",
        "This link expires in {number} hours": "Deze link verloopt over {number} uur",
        "This password reset link will expire in {count} minutes.": "Deze link om je wachtwoord te herstellen verloopt over {count} minuten.",
        "This shipping rate is not applicable to your cart.": "Dit verzendtarief is niet van toepassing op je winkelwagen.",
        "This task is reserved by {userName}": "Deze task is gereserveerd door {userName}",
        "This task is reserved by {userName}.": "Deze task is gereserveerd door {userName}.",
        "Tiktoc link": "Tiktoc-link",
        "TikTok": "TikTok",
        "Times used": "Gebruikte tijden",
        "Title": "Titel",
        "title": "titel",
        "to": "tot",
        "TODO": "TODO",
        "Toggle navigation": "Schakel navigatie",
        "token": "token",
        "Too Early": "Te vroeg",
        "Too Many Requests": "Te veel serververzoeken",
        "Totaal products ({number})": "Totaal producten ({number})",
        "Total": "Totaal",
        "Total excluding VAT": "Totaal exclusief BTW",
        "Total including VAT": "Totaal inclusief BTW",
        "Total saving": "Totale besparing",
        "To use Top Chefs Two Factor Authentication is required as extra security, you can set this now": "Om Topchefs te gebruiken is Two Factor Authentication nodig als extra beveiliging, u kunt dit nu instellen",
        "Trans": "Trans",
        "Translate": "Vertalen",
        "Translate It": "Vertaal het",
        "Two Factor Authentication - Backup codes": "Twee Factor Authenticatie - Back-up codes",
        "Two factor challenge": "Tweefactorige uitdaging",
        "Type": "Type",
        "Type job position": "Type functie",
        "Unauthorized": "Onbevoegd",
        "Unavailable For Legal Reasons": "Toegang geweigerd om juridische redenen",
        "Unisex": "Unisex",
        "Unknown Error": "Onbekende foutmelding",
        "Unmute client": "Ontdemp client",
        "unmuted": "ontdempt",
        "Unpack": "Uitpakken",
        "Unprocessable Entity": "Aanvraag kan niet worden verwerkt",
        "Unsubscribe": "Uitschrijven",
        "Unsupported Media Type": "Media-type niet ondersteund",
        "Up": "Omhoog",
        "Update": "Bewerk",
        "Update {name}": "Bijwerken {name}",
        "Update account": "Account bijwerken",
        "updated": "bijgewerkt",
        "Update your info here.": "Werk je gegevens hier bij.",
        "Upgrade Required": "Upgrade nodig",
        "Upload C.V *": "C.V. uploaden *",
        "Upload Motivation *": "Motivatie uploaden *",
        "URI Too Long": "Aanvraag-URL te lang",
        "URL": "URL",
        "Url": "Url",
        "url": "url",
        "Use Proxy": "Gebruik Proxy",
        "User": "Gebruiker",
        "user": "gebruiker",
        "Users": "Gebruikers",
        "users": "gebruikers",
        "Use `-1` for unlimited usage": "Gebruik `-1` voor onbeperkt gebruik",
        "USPs": "USP's",
        "Uw winkelmand is leeg": "Uw winkelmand is leeg",
        "Vacancies Overview": "Overzicht vacatures",
        "Vacancy application form": "Vacature sollicitatieformulier",
        "Vacancyfilters": "Vacaturefilters",
        "Vacancy intro": "Vacature intro",
        "Vacancy recent": "Recente vacature",
        "Value": "Waarde",
        "Values": "Waarden",
        "Variant": "Variant",
        "Variant Also Negotiates": "Variant onderhandelt ook",
        "Variants": "Varianten",
        "VAT": "BTW",
        "VAT:": "BTW:",
        "VAT number": "BTW-nummer",
        "Verify": "Controleer",
        "Verify {attribute}": "Verifieer {attribute}",
        "Verify Email Address": "Verifieer e-mailadres",
        "View": "Bekijk",
        "view": "bekijk",
        "View {name}": "Bekijk {name}",
        "View all": "Bekijk alles",
        "view all": "bekijk alles",
        "View order": "Bestelling bekijken",
        "View vacancy": "Vacature bekijken",
        "Waiting for payment conformation": "Wachten op betalingsbevestiging",
        "Warning: limited stock!": "Waarschuwing: beperkte voorraad!",
        "Washing instructions": "Wasvoorschriften",
        "Was unable to find an address with this postcode and street number": "Kon geen adres vinden met deze postcode en straatnummer",
        "Web Page": "Website",
        "Web Page Settings": "Instellingen webpagina",
        "Web Server is Down": "Webserver is onbereikbaar",
        "Website": "Website",
        "weekly": "wekelijks",
        "We have done our best, but it seems that the page you requested does not exist (anymore) or that it may have moved.": "We hebben ons best gedaan, maar het lijkt erop dat de door u opgevraagde pagina niet (meer) bestaat of dat deze is verplaatst.",
        "We have received your message. One of our employees will contact you as soon as possible.": "We hebben uw bericht ontvangen. Een van onze medewerkers neemt zo snel mogelijk contact met u op.",
        "We have received your message and will get back to you as soon as possible.": "We hebben je bericht ontvangen en nemen zo snel mogelijk contact met je op.",
        "Welcome": "Welkom",
        "Welcome back": "Welkom terug",
        "Welcome to {page}": "Welkom op {page}",
        "What is my size?": "Wat is mijn maat?",
        "When enabled, an email will be sent to the user, letting them know their account has been created.": "Indien ingeschakeld, wordt een e-mail naar de gebruiker gestuurd om hem te laten weten dat zijn account is aangemaakt.",
        "Which package suits me?": "Welk pakket past bij mij?",
        "White": "Wit",
        "Whoops!": "Oeps!",
        "With gradient": "Met kleurovergang",
        "Yellow": "Geel",
        "Yes": "Ja",
        "You are receiving this email because we received a password reset request for your account.": "Je ontvangt deze e-mail omdat we een wachtwoordherstel verzoek hebben ontvangen voor je account.",
        "You are subscribed to the newsletter": "Je bent geabonneerd op de nieuwsbrief",
        "You can contact us via the form. We look forward to hearing from you. Have you looked at the frequently asked questions? With the exception of weekends and holidays, we try to send you a response to your questions within 24 hours.": "U kunt contact met ons opnemen via het formulier. We horen graag van u. Heeft u gekeken naar de veelgestelde vragen? Met uitzondering van weekenden en feestdagen proberen we binnen 24 uur een reactie op uw vragen te sturen.",
        "Your account": "Uw account",
        "Your account for {siteName}": "Je account voor {siteName}",
        "Your account is inactive. Please contact support.": "Uw account is inactief. Neem contact op met support.",
        "Your account is inactive. Please contact support. To use the website as a guest, please refresh the page.": "Uw account is inactief. Neem contact op met support. Om de website als gast te gebruiken, vernieuwt u de pagina.",
        "Your current locale is {locale}": "Uw huidige locale is {locale}",
        "Your experience on this site is enhanced by the use of cookies.": "Uw ervaring op deze site wordt verbeterd door het gebruik van cookies.",
        "Your last login was {relativeTime} on {dateTime}": "Uw laatste login was {relativeTime} op {dateTime}",
        "Your Order": "Uw bestelling",
        "Your order": "Uw bestelling",
        "Your order is on its way!": "Je bestelling is onderweg!",
        "Your order was created.": "Uw bestelling is aangemaakt.",
        "Your Profile": "Uw profiel",
        "Your shopping cart is empty": "Uw winkelwagen is leeg",
        "YouTube": "YouTube",
        "Youtube link": "Youtube-link",
        "You will receive this email because your account is ready for {siteName}": "Je ontvangt deze e-mail omdat je account klaar is voor {siteName}",
        "validation": {
            "accepted": "{Attribute} moet worden geaccepteerd.",
            "accepted_if": "{Attribute} moet worden geaccepteerd als {other} {value} is.",
            "active_url": "{Attribute} is geen geldige URL.",
            "after": "{Attribute} moet een datum na {date} zijn.",
            "after_or_equal": "{Attribute} moet een datum na of gelijk aan {date} zijn.",
            "alpha": "{Attribute} mag alleen letters bevatten.",
            "alpha_dash": "{Attribute} mag alleen letters, nummers, underscores (_) en streepjes (-) bevatten.",
            "alpha_num": "{Attribute} mag alleen letters en nummers bevatten.",
            "array": "{Attribute} moet geselecteerde elementen bevatten.",
            "ascii": "De {attribute} mag alleen alfanumerieke tekens en symbolen van één byte bevatten.",
            "before": "{Attribute} moet een datum vóór {date} zijn.",
            "before_or_equal": "{Attribute} moet een datum vóór of gelijk aan {date} zijn.",
            "between": {
                "array": "{Attribute} moet tussen {min} en {max} waardes bevatten.",
                "file": "{Attribute} moet tussen {min} en {max} kilobytes zijn.",
                "numeric": "{Attribute} moet tussen {min} en {max} zijn.",
                "string": "{Attribute} moet tussen {min} en {max} karakters zijn."
            },
            "boolean": "{Attribute} moet ja of nee zijn.",
            "can": "{Attribute} bevat een waarde waar je niet bevoegd voor bent.",
            "confirmed": "Bevestiging van {attribute} komt niet overeen.",
            "current_password": "Huidig wachtwoord is onjuist.",
            "date": "{Attribute} moet een datum bevatten.",
            "date_equals": "{Attribute} moet een datum gelijk aan {date} zijn.",
            "date_format": "{Attribute} voldoet niet aan het formaat {format}.",
            "decimal": "De {attribute} moet {decimal} decimalen hebben.",
            "declined": "{Attribute} moet afgewezen worden.",
            "declined_if": "{Attribute} moet afgewezen worden wanneer {other} gelijk is aan {value}.",
            "different": "{Attribute} en {other} moeten verschillend zijn.",
            "digits": "{Attribute} moet bestaan uit {digits} cijfers.",
            "digits_between": "{Attribute} moet bestaan uit minimaal {min} en maximaal {max} cijfers.",
            "dimensions": "{Attribute} heeft geen geldige afmetingen.",
            "distinct": "{Attribute} heeft een dubbele waarde.",
            "doesnt_end_with": "{Attribute} mag niet eindigen met één van de volgende waarden: {values}.",
            "doesnt_start_with": "{Attribute} mag niet beginnen met één van de volgende waarden: {values}.",
            "email": "{Attribute} is geen geldig e-mailadres.",
            "ends_with": "{Attribute} moet met één van de volgende waarden eindigen: {values}.",
            "enum": "Gekozen {attribute} is ongeldig.",
            "exists": "{Attribute} bestaat niet.",
            "extensions": "{Attribute} moet een van de volgende bestandsextensies hebben: {values}.",
            "file": "{Attribute} moet een bestand zijn.",
            "filled": "{Attribute} is verplicht.",
            "gt": {
                "array": "{Attribute} moet meer dan {value} waardes bevatten.",
                "file": "{Attribute} moet groter zijn dan {value} kilobytes.",
                "numeric": "{Attribute} moet groter zijn dan {value}.",
                "string": "{Attribute} moet meer dan {value} tekens bevatten."
            },
            "gte": {
                "array": "{Attribute} moet {value} of meer waardes bevatten.",
                "file": "{Attribute} moet groter of gelijk zijn aan {value} kilobytes.",
                "numeric": "{Attribute} moet groter of gelijk zijn aan {value}.",
                "string": "{Attribute} moet minimaal {value} tekens bevatten."
            },
            "hex_color": "{Attribute} moet een geldige hexadecimale kleurcode zijn.",
            "image": "{Attribute} moet een afbeelding zijn.",
            "in": "{Attribute} is ongeldig.",
            "in_array": "{Attribute} bestaat niet in {other}.",
            "integer": "{Attribute} moet een getal zijn.",
            "ip": "{Attribute} moet een geldig IP-adres zijn.",
            "ipv4": "{Attribute} moet een geldig IPv4-adres zijn.",
            "ipv6": "{Attribute} moet een geldig IPv6-adres zijn.",
            "json": "{Attribute} moet een geldige JSON-string zijn.",
            "list": "Het veld {attribute} moet een lijst zijn.",
            "lowercase": "{Attribute} mag alleen kleine letters bevatten.",
            "lt": {
                "array": "{Attribute} moet minder dan {value} waardes bevatten.",
                "file": "{Attribute} moet kleiner zijn dan {value} kilobytes.",
                "numeric": "{Attribute} moet kleiner zijn dan {value}.",
                "string": "{Attribute} moet minder dan {value} tekens bevatten."
            },
            "lte": {
                "array": "{Attribute} moet {value} of minder waardes bevatten.",
                "file": "{Attribute} moet kleiner of gelijk zijn aan {value} kilobytes.",
                "numeric": "{Attribute} moet kleiner of gelijk zijn aan {value}.",
                "string": "{Attribute} moet maximaal {value} tekens bevatten."
            },
            "mac_address": "{Attribute} moet een geldig MAC-adres zijn.",
            "max": {
                "array": "{Attribute} mag niet meer dan {max} waardes bevatten.",
                "file": "{Attribute} mag niet meer dan {max} kilobytes zijn.",
                "numeric": "{Attribute} mag niet hoger dan {max} zijn.",
                "string": "{Attribute} mag niet uit meer dan {max} tekens bestaan."
            },
            "max_digits": "{Attribute} mag niet uit meer dan {max} cijfers bestaan.",
            "mimes": "{Attribute} moet een bestand zijn van het bestandstype {values}.",
            "mimetypes": "{Attribute} moet een bestand zijn van het bestandstype {values}.",
            "min": {
                "array": "{Attribute} moet minimaal {min} waardes bevatten.",
                "file": "{Attribute} moet minimaal {min} kilobytes zijn.",
                "numeric": "{Attribute} moet minimaal {min} zijn.",
                "string": "{Attribute} moet minimaal {min} tekens zijn."
            },
            "min_digits": "{Attribute} moet minimaal uit {min} cijfers bestaan.",
            "missing": "Het veld {attribute} moet ontbreken.",
            "missing_if": "Het veld {attribute} moet ontbreken als {other} {value} is.",
            "missing_unless": "Het veld {attribute} moet ontbreken, tenzij {other} {value} is.",
            "missing_with": "Het veld {attribute} moet ontbreken wanneer {values} aanwezig is.",
            "missing_with_all": "Het veld {attribute} moet ontbreken wanneer er {values} aanwezig zijn.",
            "multiple_of": "{Attribute} moet een veelvoud van {value} zijn.",
            "not_in": "{Attribute} is ongeldig.",
            "not_regex": "Het formaat van {attribute} is ongeldig.",
            "numeric": "{Attribute} moet een getal zijn.",
            "password": {
                "letters": "{Attribute} moet minimaal één letter bevatten.",
                "mixed": "{Attribute} moet minimaal één kleine letter en één hoofdletter bevatten.",
                "numbers": "{Attribute} moet minimaal één cijfer bevatten.",
                "symbols": "{Attribute} moet minimaal één vreemd teken bevatten.",
                "uncompromised": "Het opgegeven {attribute} komt voor in een datalek. Kies een ander {attribute}."
            },
            "present": "{Attribute} moet aanwezig zijn.",
            "present_if": "{Attribute} moet aanwezig zijn als {other} {value} is.",
            "present_unless": "{Attribute} moet aanwezig zijn tenzij {other} {value} is.",
            "present_with": "{Attribute} moet aanwezig zijn als {values} aanwezig is.",
            "present_with_all": "{Attribute} moet aanwezig zijn als {values} aanwezig zijn.",
            "prohibited": "{Attribute} is niet toegestaan.",
            "prohibited_if": "{Attribute} is niet toegestaan indien {other} gelijk is aan {value}.",
            "prohibited_unless": "{Attribute} is niet toegestaan tenzij {other} gelijk is aan {values}.",
            "prohibits": "{Attribute} is niet toegestaan in combinatie met {other}.",
            "regex": "Het formaat van {attribute} is ongeldig.",
            "required": "{Attribute} is verplicht.",
            "required_array_keys": "{Attribute} moet waardes bevatten voor {values}.",
            "required_if": "{Attribute} is verplicht indien {other} gelijk is aan {value}.",
            "required_if_accepted": "{Attribute} is verplicht indien {other} is geaccepteerd.",
            "required_if_declined": "The {attribute} field is required when {other} is declined.",
            "required_unless": "{Attribute} is verplicht tenzij {other} gelijk is aan {values}.",
            "required_with": "{Attribute} is verplicht in combinatie met {values}.",
            "required_with_all": "{Attribute} is verplicht in combinatie met {values}.",
            "required_without": "{Attribute} is verplicht als {values} niet ingevuld is.",
            "required_without_all": "{Attribute} is verplicht als {values} niet ingevuld zijn.",
            "same": "{Attribute} en {other} moeten overeenkomen.",
            "size": {
                "array": "{Attribute} moet {size} waardes bevatten.",
                "file": "{Attribute} moet {size} kilobytes groot zijn.",
                "numeric": "{Attribute} moet {size} zijn.",
                "string": "{Attribute} moet {size} tekens zijn."
            },
            "starts_with": "{Attribute} moet beginnen met een van de volgende: {values}.",
            "string": "{Attribute} moet een tekst zijn.",
            "timezone": "{Attribute} moet een geldige tijdzone zijn.",
            "ulid": "De {attribute} moet een geldige ULID zijn.",
            "unique": "{Attribute} is al in gebruik.",
            "uploaded": "Het uploaden van {attribute} is mislukt.",
            "uppercase": "{Attribute} mag alleen hoofdletters bevatten.",
            "url": "{Attribute} moet een geldige URL zijn.",
            "uuid": "{Attribute} moet een geldige UUID zijn.",
            "attributes": {
                "address": "adres",
                "affiliate_url": "partner-URL",
                "age": "leeftijd",
                "amount": "bedrag",
                "announcement": "aankondiging",
                "area": "regio",
                "audience_prize": "publieksprijs",
                "available": "beschikbaar",
                "billing_address.city": "Plaats",
                "billing_address.company_name": "Bedrijfsnaam",
                "billing_address.country": "Land",
                "billing_address.postcode": "Postcode",
                "billing_address.street_name": "Straatnaam",
                "billing_address.street_number": "Huisnummer",
                "billing_address.street_number_addition": "Toevoeging",
                "billing_address.vat_number": "BTW nummer",
                "birthday": "verjaardag",
                "body": "lichaam",
                "city": "stad",
                "compilation": "compilatie",
                "concept": "concept",
                "conditions": "voorwaarden",
                "consultant_id": "Consultant",
                "content": "inhoud",
                "country": "land",
                "cover": "omslag",
                "created_at": "aangemaakt op",
                "creator": "maker",
                "currency": "valuta",
                "current_password": "huidig wachtwoord",
                "customer": "klant",
                "date": "datum",
                "date_of_birth": "geboortedatum",
                "dates": "data",
                "day": "dag",
                "deleted_at": "verwijderd op",
                "description": "omschrijving",
                "display_type": "weergavetype",
                "district": "wijk",
                "duration": "tijdsduur",
                "email": "e-mailadres",
                "excerpt": "uittreksel",
                "filter": "filter",
                "finished_at": "klaar om",
                "first_name": "voornaam",
                "gender": "geslacht",
                "grand_prize": "grote Prijs",
                "group": "groep",
                "hour": "uur",
                "image": "afbeelding",
                "image_desktop": "bureaubladafbeelding",
                "image_main": "hoofdafbeelding",
                "image_mobile": "mobiel beeld",
                "images": "afbeeldingen",
                "is_audience_winner": "is publiekswinnaar",
                "is_hidden": "is verborgen",
                "is_subscribed": "is geabonneerd",
                "is_visible": "is zichtbaar",
                "is_winner": "is winnaar",
                "items": "artikels",
                "key": "sleutel",
                "last_name": "achternaam",
                "lesson": "les",
                "line_address_1": "adresregel 1",
                "line_address_2": "adresregel 2",
                "login": "Log in",
                "message": "bericht",
                "middle_name": "tweede naam",
                "minute": "minuut",
                "mobile": "mobiel",
                "month": "maand",
                "name": "naam",
                "national_code": "landcode",
                "number": "nummer",
                "password": "wachtwoord",
                "password_confirmation": "wachtwoordbevestiging",
                "phone": "telefoonnummer",
                "photo": "foto",
                "portfolio": "portefeuille",
                "postal_code": "postcode",
                "preview": "voorbeeld",
                "price": "prijs",
                "product_id": "product-ID",
                "product_uid": "product-UID",
                "product_uuid": "product-UUID",
                "promo_code": "promo-code",
                "province": "provincie",
                "quantity": "aantal",
                "reason": "reden",
                "recaptcha_response_field": "recaptcha-antwoordveld",
                "referee": "scheidsrechter",
                "referees": "scheidsrechters",
                "reject_reason": "reden afwijzen",
                "remember": "onthouden",
                "restored_at": "hersteld op",
                "result_text_under_image": "antwoord tekst onder afbeelding",
                "role": "rol",
                "role_id": "rol",
                "rule": "regel",
                "rules": "reglement",
                "second": "seconde",
                "sex": "geslacht",
                "shipment": "verzending",
                "shipping_address.city": "Plaats",
                "shipping_address.postcode": "Postcode",
                "shipping_address.street_name": "Straatnaam",
                "shipping_address.street_number": "Huisnummer",
                "shipping_address.street_number_addition": "Toevoeging",
                "shipping_rate_id": "Verzendmethode",
                "short_text": "korte tekst",
                "size": "grootte",
                "skills": "vaardigheden",
                "slug": "naaktslak",
                "specialization": "specialisatie",
                "started_at": "begon bij",
                "state": "staat",
                "status": "toestand",
                "street": "straatnaam",
                "student": "leerling",
                "subject": "onderwerp",
                "tag": "label",
                "tags": "labels",
                "teacher": "docent",
                "terms": "voorwaarden",
                "terms_and_conditions": "Algemene voorwaarden en privacy verklaring",
                "test_description": "test omschrijving",
                "test_locale": "test landinstelling",
                "test_name": "test naam",
                "text": "tekst",
                "time": "tijd",
                "title": "titel",
                "type": "type",
                "updated_at": "bijgewerkt op",
                "user": "gebruiker",
                "user_id": "gebruiker",
                "user_type": "gebruikerstype",
                "username": "gebruikersnaam",
                "value": "waarde",
                "year": "jaar"
            }
        },
        "routes": {
            "frequently-asked-questions": "veelgestelde-vragen",
            "blog": "blog",
            "about-us": "over-ons",
            "general-terms": "algemene-voorwaarden",
            "products": "producten",
            "privacy-policy": "privacy-beleid",
            "cookie-policy": "cookie-beleid",
            "contact": "contact",
            "search": "zoeken"
        },
        "actions": {
            "accept": "Aanvaarden",
            "action": "Actie",
            "actions": "Acties",
            "add": "Toevoegen",
            "admin": "Beheerder",
            "agree": "Akkoord",
            "archive": "Archiveren",
            "assign": "Toewijzen",
            "associate": "Associëren",
            "attach": "Bijvoegen",
            "browse": "Bladeren",
            "cancel": "Annuleren",
            "choose": "Kiezen",
            "choose_file": "Kies bestand",
            "choose_image": "Kies afbeelding",
            "click_to_copy": "Klik om te kopiëren",
            "close": "Sluiten",
            "collapse": "Inklappen",
            "collapse_all": "Alles inklappen",
            "comment": "Reageren",
            "confirm": "Bevestigen",
            "connect": "Aansluiten",
            "create": "Creëren",
            "delete": "Verwijderen",
            "detach": "Losmaken",
            "details": "Details",
            "disable": "Uitzetten",
            "discard": "Weggooien",
            "done": "Klaar",
            "down": "Omlaag",
            "duplicate": "Dupliceren",
            "edit": "Bewerken",
            "enable": "Inschakelen",
            "expand": "Uitvouwen",
            "expand_all": "Alles uitvouwen",
            "explanation": "Uitleg",
            "export": "Exporteren",
            "file": "Bestand",
            "files": "Bestanden",
            "go_home": "Ga naar startpagina",
            "hide": "Verbergen",
            "home": "Startpagina",
            "image": "Afbeelding",
            "impersonate": "Imiteren",
            "impersonation": "Imitatie",
            "import": "Importeren",
            "introduction": "Invoering",
            "like": "Leuk vinden",
            "load": "Laden",
            "localize": "Lokaliseren",
            "log_in": "Log in",
            "log_out": "Uitloggen",
            "named": {
                "add": "Voeg {name} toe",
                "choose": "Kies {name}",
                "create": "Creëer {name}",
                "delete": "Verwijder {name}",
                "duplicate": "Dupliceer: naam",
                "edit": "Bewerk {name}",
                "hide": "Verberg {name}",
                "import": "Importeer {name}",
                "new": "Nieuw {name}",
                "restore": "Herstel {name}",
                "save": "Sla {name} op",
                "search": "Zoek {name}",
                "show": "Toon {name}",
                "update": "Bijwerken {name}",
                "view": "Bekijk {name}"
            },
            "new": "Nieuw",
            "no": "Nee",
            "open": "Open",
            "open_website": "Open op de website",
            "preview": "Voorbeeld",
            "price": "Prijs",
            "record": "Dossier",
            "restore": "Herstel",
            "save": "Opslaan",
            "save_and_close": "Opslaan en sluiten",
            "save_and_return": "Opslaan en teruggaan",
            "search": "Zoeken",
            "select": "Selecteren",
            "select_all": "Selecteer alles",
            "send": "Versturen",
            "settings": "Instellingen",
            "show": "Toon",
            "show_all": "Toon alles",
            "sign_in": "Aanmelden",
            "solve": "Oplossen",
            "start": "Begin",
            "stop": "Stop",
            "submit": "Indienen",
            "subscribe": "Abonneren",
            "switch": "Veranderen",
            "switch_to_role": "Verander naar rol",
            "tag": "Label",
            "tags": "Labels",
            "target_link": {
                "blank": "Open in een nieuw venster",
                "parent": "Open in een bovenliggend frame",
                "self": "Open in een huidig ​​venster",
                "top": "Open in het bovenste frame"
            },
            "translate": "Vertalen",
            "translate_it": "Vertaal het",
            "unpack": "Uitpakken",
            "unsubscribe": "Uitschrijven",
            "up": "Omhoog",
            "update": "Updaten",
            "user": "Gebruiker",
            "view": "Weergave",
            "yes": "Ja"
        },
        "auth": {
            "failed": "Deze combinatie van e-mailadres en wachtwoord is niet geldig.",
            "password": "Wachtwoord is onjuist.",
            "throttle": "Te veel mislukte aanmeldpogingen. Probeer het nog eens over {seconds} seconden."
        },
        "pagination": {
            "next": "Volgende &raquo;",
            "previous": "&laquo; Vorige"
        },
        "http-statuses": {
            "0": "Onbekende foutmelding",
            "100": "Doorgaan",
            "101": "Protocolwissel",
            "102": "Verwerken",
            "200": "Oké",
            "201": "Aangemaakt",
            "202": "Aanvaard",
            "203": "Niet-gemachtigde informatie",
            "204": "Geen inhoud",
            "205": "Inhoud opnieuw instellen",
            "206": "Gedeeltelijke inhoud",
            "207": "Meerdere statussen",
            "208": "Al gemeld",
            "226": "Ik ben gebruikt",
            "300": "Meerkeuze",
            "301": "Definitief verplaatst",
            "302": "Gevonden",
            "303": "Zie andere",
            "304": "Niet gewijzigd",
            "305": "Gebruik Proxy",
            "307": "Tijdelijke omleiding",
            "308": "Definitieve omleiding",
            "400": "Foute aanvraag",
            "401": "Niet geautoriseerd",
            "402": "Betaalde toegang",
            "403": "Verboden toegang",
            "404": "Niet gevonden",
            "405": "Methode niet toegestaan",
            "406": "Niet aanvaardbaar",
            "407": "Authenticatie op de proxyserver verplicht",
            "408": "Aanvraagtijd verstreken",
            "409": "Conflict",
            "410": "Verdwenen",
            "411": "Lengte vereist",
            "412": "Niet voldaan aan de vooraf gestelde voorwaarde",
            "413": "Aanvraag te groot",
            "414": "Aanvraag-URL te lang",
            "415": "Media-type niet ondersteund",
            "416": "Bereik niet bevredigend",
            "417": "Niet voldaan aan verwachting",
            "418": "Ik ben een theepot",
            "419": "Pagina verlopen",
            "421": "Verkeerd geadresseerd verzoek",
            "422": "Aanvraag kan niet worden verwerkt",
            "423": "Afgesloten",
            "424": "Gefaalde afhankelijkheid",
            "425": "Te vroeg",
            "426": "Upgrade nodig",
            "428": "Voorwaarde nodig",
            "429": "Te veel requests",
            "431": "Headers van de aanvraag te lang",
            "444": "Verbinding gesloten zonder reactie",
            "449": "Opnieuw proberen met",
            "451": "Toegang geweigerd om juridische redenen",
            "499": "Klant Gesloten Verzoek",
            "500": "Interne serverfout",
            "501": "Niet geïmplementeerd",
            "502": "Slechte poort",
            "503": "Dienst niet beschikbaar",
            "504": "Gateway-time-out",
            "505": "HTTP-versie wordt niet ondersteund",
            "506": "Variant onderhandelt ook",
            "507": "Onvoldoende opslag",
            "508": "Loop gedetecteerd",
            "509": "Bandbreedte overschreden",
            "510": "Niet verlengd",
            "511": "Netwerkauthenticatie vereist",
            "520": "Onbekende foutmelding",
            "521": "Webserver is onbereikbaar",
            "522": "Connectie duurt te lang",
            "523": "Herkomst is onbereikbaar",
            "524": "Time-out opgetreden",
            "525": "SSL-handshake mislukt",
            "526": "Ongeldig SSL-certificaat",
            "527": "Railgun foutmelding",
            "598": "Time-outfout netwerk lezen",
            "599": "Fout bij time-out netwerkverbinding",
            "unknownError": "Onbekende foutmelding"
        },
        "passwords": {
            "reset": "Het wachtwoord van uw account is gewijzigd.",
            "sent": "We hebben een e-mail verstuurd met instructies om een nieuw wachtwoord in te stellen.",
            "throttled": "Gelieve even te wachten en het dan opnieuw te proberen.",
            "token": "Dit wachtwoordhersteltoken is niet geldig.",
            "user": "Geen gebruiker bekend met dit e-mailadres."
        }
    }
}
